.mc-seepassword-btn {
  position: absolute;
  bottom: 0;
  color: $login-gray-color;
  right: 0;
  border: none;
  background: transparent;
  height: calc(1.5em + 0.75rem + 5px);
  width: 12%;
  &:focus{
    outline: none;
  }
}

@media only screen and (max-width: $extra-small-device) {
  .mc-seepassword-btn {
    width: 17%;
  }
}
