/*.validation-error {
  .border-error {
    border: 1px solid $mc-red-color-text !important;
  }

  .error-msg {
    font-size: 15px;
    color: $mc-red-color-text;
    text-align: right;
    margin-bottom: 5px;
  }

  .error-msg-left {
    font-size: 15px;
    color: $mc-red-color-text;
    text-align: left;
    margin-bottom: 5px;
  }
}*/
