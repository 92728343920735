.mc-management-area {
  position: relative;

  .mc-management-table {
    padding-bottom: 60px;
  }

  /*Error div*/
  .mc-error {
    position: relative;
    height: fit-content;
    margin-bottom: 30px;
    top: -4px;
  }

  /*Popup wrapper*/
  .mc-popup-wrapper {
    position: fixed;
    height: 80px;
    bottom: -110px;
    color: $white;
    background: $login-blue-color;
    transition: bottom .2s ease-in-out;

    -webkit-box-shadow: 0px -10px 51px -15px rgba(0,0,0,0.68);
    -moz-box-shadow: 0px -10px 51px -15px rgba(0,0,0,0.68);
    box-shadow: 0px -10px 51px -15px rgba(0,0,0,0.68);

    /*Wrapper for user popup buttons*/
    .mc-user-popup-wrapper {
      width: fit-content;
      margin: 0 auto;

      .mc-border-left {
        border-left: 1px solid $white;
      }

      .mc-border-right {
        border-right: 1px solid $white;
      }

      .mc-border-left-right {
        border-left: 1px solid $white;
        border-right: 1px solid $white;
      }
    }

    /*Popup button*/
    .mc-popup-button {
      display: inline-block;
      width: fit-content;
      height: -webkit-fill-available;
      padding: 10px 20px;
      text-align: center;
      cursor: pointer;

      i {
        font-size: 25px;
      }

      &:hover {
        opacity: 0.8;
        transition: 0.3s;
      }
    }
  }

  /*Show popup*/
  .mc-popup-wrapper-show {
    bottom: 0;
    transition: bottom .5s ease-in-out;
  }
}
