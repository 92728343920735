.card-wrapper {
  display: grid;
  grid-gap: 25px;
  grid-auto-rows: 1fr;
  grid-template-columns: repeat(auto-fit, minmax(450px, 1fr) );
}

.card-wrapper-medium {
  grid-template-columns: repeat(auto-fit, minmax(550px, 1fr) );
}

// CARD

.card {
  border-radius: 4px;
  background-color: #fff;
  padding: 15px;
  margin-bottom: 10px;

  .card-inner-wrapper {
    display: flex;
    align-items: center;
  }

  &.card-flex-end {
    display: flex;
    justify-content: flex-end;
  }

  &_title {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    color: #fff;
  }

  &_description {
    font-size: 14px;
    font-weight: 500;
    color: #fff;
  }
}


.info-card {
  box-sizing: border-box;
  border-radius: 4px;
  background-color: #FFF;
  border: 1px solid rgba(1,21,34,0.2);
  box-shadow: 1px 1px 2px 0 rgba(0,0,0,0.2);
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: $margin-small;
  display: flex;
  flex-direction: column;

  .info-card-label-value {
    display: flex;
    align-items: center;
    flex: 1 1 25%;
    .label {
      display: flex;
      align-items: center;
      margin-right: $margin-smallest;
      color: rgba(59, 69, 124, 0.5);
      font-size: $font-small;
      line-height: 20px;
    }
    .value {
      color: $cc-subtitle-color;
      font-size: $font-small;
      font-weight: 700;
      line-height: 20px;
    }
  }
}


.settings-card {
  &_header {
    display: flex;
    justify-content: space-between;
    margin: -15px;
    padding: 20px;
    border-bottom: 1px solid rgba(1,21,34,0.2);
    align-items: center;
    &_left {
      display: flex;
      align-items: center;
      gap: 10px;
      &_icon {
        ::ng-deep svg {
          width: 30px;
          height: 30px;
          g  {
            opacity: 1;
          }
        }
      }
      &_title {
        color: $cc-primary-color;
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
      }
    }
    &_right {
      display: flex;
      gap: 10px;
      align-items: center;
      cursor: pointer;
      &_title {
        color: $cc-primary-color;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
      }
      &_icon {
        ::ng-deep svg {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
  &_confirm {
    margin: 0 -15px;
    padding: 20px 20px 10px 20px;
    border-top: 1px solid rgba(1, 21, 34, 0.2);
  }
}

.input-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  grid-column-gap: 15px;
  grid-row-gap: 20px;
  width: 100%;
  .mat-form-field-wrapper {
    margin: 0 !important;
    padding: 0;
  }
}

.input-inner-icon {
  position: absolute;
  top: 5px;
  right: -3px;
  background-color: #fff;
}
