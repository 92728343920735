/*Status component*/
.mc-status-active {
  color: $status-active;
}
.mc-status-inactive {
  color: $status-inactive;
}
.mc-status-draft {
  color: $status-draft;
}
.mc-status-pending {
  color: $status-pending;
}
.mc-status-print {
  color: $status-blocked;
}
.mc-status-running {
  color: $status-running;
}
.mc-status-scheduled {
  color: $status-scheduled;
}
.mc-status-stopped {
  color: $status-stopped;
}
.mc-status-paused {
  color: $status-paused;
}
.mc-status-blocked {
  color: $status-blocked;
}
.mc-status-completed{
  color: $status-completed;
}
.mc-status-send_now{
  color: $status-send-now
}
.mc-status-error {
  color: $status-error;
}
.mc-status-none {
  color: $status-none;
}
