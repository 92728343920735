/*Job create edit modal wrapper*/
.mc-job-create-edit-modal {
  .form-check-cronex-label {
    width: 400px;
  }

  .mc-job-label {
    display: block;
    font-size: 14px;
    font-weight: 500;
  }

  .modal-dialog {
    max-width: 1400px !important;
  }
  .modal-header {
    border-bottom: 3px solid $mc-gray-border-color;
    padding-left: 0;
  }


  .modal-body {
    overflow-y: auto;
    background: #f5f5f5;
    height: 600px;

    input {
      width: 100% !important;
    }
    textarea {
      width: 100%;
    }
  }
  .modal-content {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    padding: 20px 40px 40px !important;

    /*Close button*/
    .mc-job-create-edit-modal-close-button {
      @extend %modalCloseBtn;
      padding: 15px !important;
    }

    .mc-notify-wrapper {
      position: relative;
      height: 30px;
    }

    .mc-schedule-input-fields-wrapper {
      height: 150px;
    }
  }

}


  /*Open modal button*/
  .mc-job-create-edit-modal-button {
    color: $mc-blue-text-color;
    font-weight: 500;
    width: fit-content;
    float: right;


  }
.mc-div-margin {
  margin-bottom: 10px;
}

/*Overview jobs*/
.mc-job-overview {
  .mc-job-label {
    color: $mc-gray-line;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
  }

  .mc-edit-job-wrapper {
    margin-left: .8rem;
  }
}
