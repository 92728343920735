.custom-table {
  width: 100%;
  box-shadow: 1px 1px 4px 0px rgb(0 0 0 / 20%);
  border-collapse: collapse;
  flex: 1;

  thead {
    height: 50px;
    border-radius: 4px;
    text-align: left;
    border-bottom: 1px solid rgba(0,0,0,0.1);

    tr {
      height: 44px;
    }

    th:first-child {
      padding-left: $padding-large;
      text-align: left;
    }
    th:last-child{
      padding-right: $padding-large;
      text-align: right;
    }
  }

  tbody {
    tr {
      height: 44px;
      border-bottom: 1px solid rgba(0,0,0,0.1);
    }

    td:first-child {
      padding-left: $padding-large;
      text-align: left;
    }
    td:last-child{
      padding-right: $padding-large;
      text-align: right;
    }
  }
}
