/*
**Use it for generating classes with parameter
*/
@for $pixelNumber from 1 through 1000 {

  /*Height*/
  .mc-g-height-#{$pixelNumber} {
    height: #{$pixelNumber}px;
  }

  /*Padding x-os*/
  .mc-g-padding-x-#{$pixelNumber} {
    padding: 0 #{$pixelNumber}px;
  }

  /*Font size*/
  .mc-g-font-size-#{$pixelNumber} {
    font-size: #{$pixelNumber}px;
  }
}
