// custom radio-btn
[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: "";
  width: 6px;
  height: 6px;
  background: $mc-blue-text-color;
  position: absolute;
  top: 6px;
  left: 6px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

//ACCOUNT-INFO STYLES
.mc-account-info {

  .mc-account-info-title {
    color: $mc-blue-text-color;
    font-weight: 400;
  }
  .mc-account-info-text {
    font-weight: 500;
  }
  mc-two-fa-account-authentication-modal {
    color: $mc-blue-text-color;
  }

  .fa-check, .fa-pen {
    color: $mc-blue-text-color;
    font-size: small;
  }

  .fa-times {
    color: $error_color;
    font-size: small;
  }

  mc-change-password-modal {
    color: $mc-blue-text-color;
    text-decoration: underline;
  }

  .mc-notify-wrapper {
    padding-top: 20px;
  }
}

//ACCOUNT-INFO AUTHENTICATION MODAL
.mc-account-info-authentication-modal {
  text-align: center;

  .modal-content {
    border-radius: 0;
    .modal-header {
      border-bottom: none;
      padding-bottom: 0;
      .close {
        font-size: 60px;
        font-weight: 100;
        outline: none;
        padding: 0 10px;
        color: $mc-gray-border-color;
      }
    }
    .modal-body {
      padding: 0 9% 3% 9%;
      .modal-title {
        margin-bottom: 5%;
        border-bottom: 3px solid $mc-gray-border-color;
      }
      .mc-notify-wrapper {
        padding-top: 20px;
      }
    }
    .modal-footer {
      border-top: none;
      padding: 5%;
      .modal-save {
        background-color: $mc-blue-text-color;
        color: $bg_color;
        margin: 0 auto;
        padding: 4px 25px;
        border: none;
        outline: none;
        -webkit-border-radius: 50px 50px 50px;
        -moz-border-radius: 50px 50px 50px;
        border-radius: 50px 50px 50px;
      }
    }
  }
}

//ACCOUNT-INFO CHANGE-PASSWORD MODAL
.mc-change-password-modal {
  text-align: center;

  .mc-change-password-area {
    .mc-notify-message-wrapper {
      margin-top: 190px;
    }
    .mc-btn-group {
      display: flex;
      justify-content: space-between;
      .mc-cancel-btn {
        width: 25%;
        height: 40%;
        margin-top: 47px;
        background-color: $cancel-button-color;
        color: $bg_color;
        border: 1px solid $cancel-button-color;
        border-radius: 20px;
      }
        .mc-confirm-btn {
        width: 50%;
        height: 50%;
        margin-top: 40px;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        border-radius: 20px;
        background-color: $login-blue-color;
        border: 1px solid $login-blue-color;
        color: $bg_color;
      }
      .mc-disable-btn {
        width: 50%;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        border-radius: 20px;
        background-color: $login-gray-color;
        border: 1px solid $login-gray-color;
        color: $bg_color;
      }
    }
    padding: 0;
    background-size: cover;
    box-shadow: inset 0 0 0 1000px rgba(58,120,151,.65);
    .mc-login-header {
      background-color: $login-header-color;
      padding: 40px;
      img{
        height: 45px;
        margin-top: 12px;
      }
      .mc-card-title {
        margin-top: 15px;
      }
    }
    .mc-login-card-body {
      margin-top: 50px;
      padding: 40px;
      .mc-change-password-body {
        padding: 15%;
      }
      .mc-seepassword-btn {
        right: 10px;
        bottom: -3px;
      }
    }
    // enter password section
    .tab-content {
      position: relative;
    }
    .mc-notify-message-wrapper {
      padding-top: 20px;
    }
    .mc-login-password-fields {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-right: 20px;
      .label-position {
        display: flex;
        margin-bottom: 3px;
        color: $login-label-color;
      }
      input {
        border: 1px solid $login-input-color;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        padding: 10px;
        font-size: 14px;
        font-weight: 400 !important;
      }
    }
    .mc-password-policy {
      padding-left: 17px;
      text-align: left !important;
      .mc-password-policy-title {
        color: $login-label-color;
        text-align: left;
      }
      p {
        //font-weight: 500;
      }
      .form-group {
        margin: 0;
        font-size: 14px;
        label {
          text-align: left;
          .fill-control-description {
            font-size: 9px;
            color: $text-color;
          }
        }
      }
    }



    // generate password section
    .rule-wrapper {
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
    }
    .rule-checkbox-wrapper {
      display: flex;
      flex-direction: row;
      flex: 1;
      .form-group {
        margin: 0 0.5rem 0 0;
      }
    }
    .password-wrapper {
      flex-direction: column;
    }
    .password-input-wrapper {
      justify-content: space-between;
    }
  }

  // Responsive
  @media only screen and (max-width: $small-device) {
    .mc-setup-area {
      .mc-login-header {
        img {
          height: 60px;
        }
      }
      .mc-login-card .mc-login-card-body {
        padding: 0 30px 30px;
      }
      .mc-login-password-fields.mc-divider {
        border-right: none;
      }
      .mc-password-policy {
        p {
          width: 100%;
        }
        .mc-password-policy-requirements {
          column-count: 2;
        }
      }
    }
  }
  @media only screen and (max-width: 460px) {
    .mc-setup-area {
      .mc-login-card-body ul {
        flex-direction: column;
        align-items: center;

        li a {
          line-height: 1;
          padding-top: 5px;
          padding-bottom: 5px;
        }

        li:first-of-type {
          &:after {
            display: block;
            text-align: center;
          }
        }
      }
      .mc-input-password {
        margin-bottom: 0;
      }
      .mc-login-card .mc-login-card-body form {
        padding-top: 50px;
      }
      .mc-password-policy {
        margin-top: 10px;
      }
      .mc-login-btn-area {
        padding-top: 30px;
      }
      .mc-seepassword-btn {
        width: 17%;
      }
    }
  }
}

//PERSONAL-INFO STYLES
.mc-personal-info {

  .mc-personal-info-title {
    color: $mc-blue-text-color;
    font-weight: 400;
  }
  .mc-personal-info-wrapper {
    padding: 2% 5% 5%;
    border-radius: 5px;
    background-color: $personal-info-box;
    h5 {
      display: inline-block;
      font-weight: 400;
    }
    .mc-personal-info-edit {
      color: $mc-blue-text-color;
      font-weight: 400;
      &:hover {
        color: $mc-blue-text-color;
      }
      .fa-pen {
        color: $mc-blue-text-color;
        font-size: small;
      }
    }
    .mc-notify-wrapper {
      position: relative;
      top: -18px;
      margin-bottom: 9%;
    }
    hr {
      margin-top: 0;
      margin-bottom: 2%;
      background-color: $mc-gray-border-color;
      height: 0.1px;
    }
    .user-image {
      width: 200px;
      max-width: 100%;
      display: block;
    }
    .top-margin {
      margin-top: 2%;
      .user-label {
        font-size: 12px;
        font-weight: 600;
        color: $mc-gray-border-color;
      }
      .user-info {
        margin-top: -1px;
        //color: $black;
        //font-size: 16px;
        //font-weight: 400;
      }
    }
  }
}

// EDIT PERSONAL INFO MODAL
.mc-edit-personal-info-modal {
  //text-align: center;
  .modal-dialog {
    max-width: 1200px;
    top: 30px;
    left: 100px;
  }
  .gender, .image {
    font-size: 100%;
    font-weight: 500;
  }

  .modal-content {
    border-radius: 0;
    max-width: 1200px;
    .modal-header {
      border-bottom: none;
      padding-bottom: 0;
      .close {
        font-size: 60px;
        font-weight: 100;
        outline: none;
        padding: 0 10px;
        color: $mc-gray-border-color;
      }
    }
    .modal-body {
      padding: 0 9% 3% 9%;
      .modal-title {
        margin-bottom: 5%;
        border-bottom: 3px solid $mc-gray-border-color;
      }
      .mc-notify-wrapper {
        position: relative;
        top: -18px;
        margin-bottom: 9%;
      }
      .personal-image {
        width: 200px;
        max-width: 100%;
        max-height: 215px;
        border: 1px solid grey;
        display: block;
      }
      .hidden-input {
        opacity: 0;
        z-index: 10;
        position: absolute;
        right: 12px;
        width: 104px;
        height: 26px;
      }
      .upload-img {
        color: $mc-blue-text-color;
        float: right;
        position: relative;
        top: 10px;
        font-size: 90%;
      }
      label {
        margin-bottom: 1%;
        color: $login-label-color;
        font-size: 100%;
        font-weight: 500;
      }
      input, select {
        border: 1px solid $login-input-color;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        //padding: 10px;
        font-size: 14px;
        height: 40px;
      }
      .required-input {
        color: $mc-red-color-text;
      }
      .mc-confirm-btn {
        background-color: $mc-blue-text-color;
        color: $bg_color;
        padding: 4px 25px;
        float: right;
        border: none;
        outline: none;
        -webkit-border-radius: 50px 50px 50px;
        -moz-border-radius: 50px 50px 50px;
        border-radius: 50px 50px 50px;
        position: absolute;
        bottom: 20px;
        right: 40px;
      }
      .mc-disable-btn {
        padding: 4px 25px;
        float: right;
        border: none;
        outline: none;
        -webkit-border-radius: 50px 50px 50px;
        -moz-border-radius: 50px 50px 50px;
        border-radius: 50px 50px 50px;
        background-color: $login-gray-color !important;
        color: $bg_color;
      }
    }
  }
}


