.mc-edit-user {

  .mc-edit-user-general-data {
    background: $mc-gray-background;
    border-radius: 10px;
    padding: 20px 70px;

    .mc-create-user-notify-wrapper {
      width: 100%;
    }
  }

  .mc-edit-user-general-data-user-name {
    border-bottom: 1px solid $mc-gray-line;
    font-size: 22px;
    font-weight: 500;
    padding-bottom: 10px;
    text-align: center;
  }

  .mc-edit-user-general-data-personal-info {
    padding: 40px 0 20px 0;
    border-bottom: 1px solid lighten($mc-gray-line, 10%);
  }

  .mc-edit-user-personal-info-link:not([href]){
    font-size: 20px;
    color: $login-blue-color;
    padding-bottom: 10px;
    position: relative;
    padding-right: 22px;

    .fa-pen {
      font-size: 16px;
      position: absolute;
      top: 3px;
      right: 0;
    }
  }

  .mc-new-user-image {
    min-width: 100%;
    height: 210px;
    cursor: auto;
    background-color: $white;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-origin: content-box;
  }

  .mc-edit-user-preview-data {
    padding: 0;
  }

  .mc-edit-user-first-block {
    border-bottom: 1px solid lighten($mc-gray-line, 10%);
    margin: 0 0 0 30px;
  }

  .mc-edit-user-second-block {

    margin: 0 0 0 30px;
  }

  .mc-second-block-fields {

    padding-left: 0;
  }

  .mc-edit-user-preview-data {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
  }

  .mc-edit-user-preview-data-field-name {
    color: $mc-gray-line;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    flex-basis: 100px;
  }

  .mc-edit-user-preview-data-field-value {
    font-size: 16px;
    flex: 1;
  }

  .mc-edit-user-general-data-roles {
    padding-top: 30px;

    .mc-edit-user-personal-info-roles {
      border-bottom: 1px solid lighten($mc-gray-line, 10%);
    }
  }

  .mc-create-new-user-entity-name {
    color: $mc-blue-text-color;
    font-size: 14px;
    font-weight: 500;
    padding-right: 5px;
    text-transform: uppercase;
  }
}
.info-container-secondary {
  background-color: #F8f8f8;
  border-radius: 4px;
  border: 1px solid #F2F0F0;
  margin: 0 0 10px 0;
  padding: 20px;

  span {
    text-transform: none;
    font-size: 1rem;
    font-weight: 400;
    margin: 0 10px;
  }
}

.info-cell {
  border-radius: 4px;
  border: 1px solid #F2F0F0;
  background-color: #FFF;
  min-height: 96px;
  padding: 20px;
  display: flex;


  .icon-holder {
    float: left;
  }

  .icon-holder img {
    height: 40px;
    width: auto;
    margin: 0 10px 0 0;
  }

}

.account-data {
  align-self: center;

  .info-title {
    font-size: 1.2rem;
    font-weight: 500;
  }
}
