@import '../projects/miticon-ui/mc-core-ui/src/lib/assets/scss/variables';

.mcc-modal {
  background: rgba(0, 0, 0, 0.75);


  .modal-dialog {
    width: 1400px;
    max-width: 90%;
    height: calc(100% - 60px);
    margin: 30px auto;
    justify-content: center;

    .height-auto {
      .content-body {
        height: auto;
      }
    }
  }

  .modal-content {
    padding: 20px;
    border: none;
    height: 100%;
    .content-body {
      overflow: auto;
    }
  }

  .modal-body {
    padding: 0;
    //overflow: auto;
  }

  .modal-body-main-content {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
    overflow: auto;
    width: 100%;
    height: calc(100vh - 260px);
  }

  .close {
    font-weight: normal;
    padding: 0;
    font-size: 50px;
    margin: -10px 30px 0 0;
    position: absolute;
    right: 0;
    z-index: 10;

  }

  .modal-header {
    padding-right: 50px;
  }
  .content-wrapper{
    .content-body{
      height: calc(100vh - 260px);
    }
    .content-header{
      margin-right: 50px;
    }
  }


}

.modal-small {
  .modal-dialog{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    min-width: 800px;
    max-width: 100%;
  }
  .modal-content{
    height: auto;
    max-height: 100%;
  }
  .modal-body-main-content{
    height: auto;
    max-height: calc(100vh - 260px);
    min-height: 100px;
  }
}

.modal-medium {
  .modal-dialog{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    min-width: 1020px;
    max-width: 100%;
  }
  .modal-content{
    height: auto;
    max-height: 100%;
  }
  .modal-body-main-content{
    height: auto;
    max-height: calc(100vh - 260px);
    min-height: 100px;
  }
}

.wrapper-padding {
  padding-top: 10px;
  .content-body {
    padding: 0 !important;
  }
}

.content-wrapper{
  position: relative;
  flex: 1 1 auto;

  .content-header{
    padding: 20px 1rem 0 1rem;
    border-bottom: 1px solid #dee2e6;
    margin-top: -20px;
    width: calc(100% + 40px);
    margin-left: -20px;
  }
  .content-header-title {
    font-family: $font-secondary;
    font-size: 20px;
    font-weight: 800;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: $cc-action-button-color;
    text-transform: uppercase;
    margin-left: 20px;
  }
  .content-body{
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
    //overflow: auto;
    width: 100%;
    .mcc-fi{
      margin-bottom: 15px;
    }
    .newStyle {
      margin-bottom: -10px !important;
    }
    .content-section{
      padding: 0 20px 20px;
      margin-bottom: 20px;
      h2{
        margin-left: -20px;
        font-size: 22px;
        padding: 8px 20px;
        margin-bottom: 20px;
        background: #f3f3f3;
        border-radius: 4px;
      }
    }

  }
  .content-footer {
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    //justify-content: flex-end;
    padding: 1rem;
    border-top: 1px solid #dee2e6;

    > :not(:last-child) {
      margin-right: 1rem;
    }
    .move-right{
      margin-left:auto;
    }
  }
  .section {
    display: flex;
    flex-direction: column;
    box-shadow: 1px 1px 2px 0px #00000033;
    background-color: $filter-bg;
    border-radius: 4px;
    margin-bottom: 20px;
    &_header {
      display: flex;
      justify-content: space-between;
      padding: 10px 20px;
      align-items: center;
      &_title {
        font-size: 20px;
        font-weight: 800;
        text-transform: uppercase;
        color: $cc-action-button-color;
      }
    }
    &_content {
      padding: 15px 20px;
      display: grid;
      grid-template-columns: 50% 50%;
      grid-column-gap: 15px;
      grid-row-gap: 20px;
      border-top: 1px solid #D6D7E1;
      &_item {
        display: flex;
        flex-direction: column;
      }
      &_text {
        font-size: 16px;
        color: $dialog-text-color;
      }
    }
  }
}

.back-option {
  cursor: pointer;
  color: $cc-primary-color;
  font-size: 24px;
  font-weight: 800;
  text-transform: uppercase;
  margin-left: 20px;
}

.cardbox {
  width: 100%;
  background-color: white;
  border-radius: 4px;
  margin-top: 10px;
  padding: 20px;
  box-shadow: 1px 1px 2px 0px #00000033;
  &_title {
    font-size: 20px;
    font-weight: 700;
    color: $dialog-text-color;
  }
}

/**/
.info-data{
  .row{
    align-items: start;
    padding: 7px;
    margin: 0;
  }
  .title{
    text-transform: uppercase;
    font-size: 0.75rem;
    padding-right: 0;
    margin-top:3px;

  }
  &.title-left{
    .title{
      text-align: left;
    }
  }
  &.title-right{
    .title{
      text-align: right;
    }
  }
}

.dialog-row-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 4fr));
  grid-column-gap: 15px;
  grid-row-gap: 20px;
  width: 100%;
  margin-top: 20px;
}


.dialog-col {
  display: flex;
  min-width: 250px;
  word-break: break-word;
  padding: 0 10px 10px 10px;

  .dialog-col-label {
    color: $dialog-text-color;
    font-size: 16px;
    margin-bottom: 5px;
    opacity: .6;
  }

  .dialog-col-value {
    color: $dialog-text-color;
    font-weight: 700;
    width: fit-content;
  }
}

.dialog-col {
  flex-direction: column;
}


.tabs {
  min-height: 64px;
  display: flex;
  padding: 0 40px;
  width: 100%;
  gap: 30px;
  justify-content: space-around;
  font-family: $font-secondary;
  &_option {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    &_title {
      color: $dialog-text-color;
      font-size: 1.125rem;
      line-height: 22px;
    }
  }
}

.activeTab {
  border-bottom: 2px solid $cc-primary-color;
  cursor: auto;
  .tabs_option_title {
    color: $cc-primary-color;
    font-weight: 700;
  }
}
