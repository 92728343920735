.mc-invoice-section {
  .mc-invoice-notify-wrapper {
    position: relative;
    top: -17px;
    margin-bottom: 30px;
    width: 100%;
  }

  a.mc-create-modal-btn {
    color: $mc-blue-text-color !important;
    cursor: pointer;
    font-weight: 500;
    &:hover {
      color: darken($mc-blue-text-color, 10%) !important;
    }
  }
}

.mc-create-invoice-modal {
  //min-width: -webkit-fill-available;
  //width: fit-content;
  //max-width: initial;

  .modal-dialog {
    max-width: 1400px;
  }

  .modal-content {
    min-height: 700px;
  }

  .mc-active {
    color: $login-blue-color;
    border-bottom: 3px solid $login-blue-color;
  }

  .mc-active-background {
    background: $login-blue-color;
  }

  .mc-semi-active {
    color: lighten($login-blue-color, 15%);
  }

  .mc-semi-active-background {
    background-color: lighten($login-blue-color, 15%);
  }

  .mc-error-field {
    border-color: $error_color;

    &:focus {
      border-color: $error_color;
      box-shadow: 0 0 0 0.2rem rgba(236, 35, 13, .25)
    }
  }

  .modal-content {
    border-radius: 0;
    padding: 10px 50px 50px 50px;
    position: relative;
  }

  .mc-create-user-modal-header {
    display: block;
    padding-left: 0;
    padding-right: 0;
    border-bottom: 0;

    button.close.mc-consumers-close-btn {
      @extend %modalCloseBtn;
    }
  }

  .modal-body {
    background: $mc-gray-background;
    border-top: 3px solid $mc-gray-line;
    position: relative;
  }

  .mc-invoice-product-form {
    section:first-of-type{
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0;
      max-width: 100%;
    }
  }

  .mc-create-invoices-form {
    .mc-table-component-wrapper {
      padding-left: 15px;
      padding-right: 0;
    }
  }

  .mc-invoice-step {
    min-height: 550px;
  }

  .form-control.mc-dates-inputs[disabled] {
      background: #e9ecef;
  }
  .mc-dates-due-date {
    flex: 1;
  }
  .mc-date-label {
    min-width: 90px;
    text-transform: capitalize;
  }

  .mc-schedule-date{
    display: none;
  }

  .mc-show-send-delayed {
    display: none;
  }
  .mc-hide {
    display: none !important;
  }

  .mc-selected-schedule {
    background: darken($mc-gray-background, 7%);
  }

  .mc-invoice-schedule-select {
    font-weight: 500;
  }

  .mc-selected-product-wrapper {
    height: 77px;
  }

  .mc-selected-products-list {
    width: 80%  ;
    //column-count: 4;
    //column-gap: 2%;
    list-style: none;
    display: flex;
    flex-wrap: wrap;

    .mc-list-item {
      background: $login-header-color;
      padding: 5px 15px;
      //break-inside: avoid-column;
      border: 1px solid darken($login-header-color, 5%);
      margin: 5px 8px;
      display: flex;
      justify-content: flex-start;

      //span:first-child {
      //  color: darken($mc-gray-text-1, 15%);
      //  padding-right: 5px;
      //}

      span {
        color: $login-label-color;
        font-weight: 500;
      }
    }
  }

  .mc-user-summary-action-btn {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    bottom: 32px;
    right: 0;
    z-index: 9;
  }

  .mc-invoice-summary-action-btn {
    padding-right: 60px;
  }

  .mc-invoice-preview-btn {
    border: 1px solid #109be3;
    border-radius: 25px;
    color: #109be3;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    padding: 6px 25px;
    text-decoration: none;
    transition: 0.25s all ease-in-out;
    &:hover{
      background: darken(#109be3, 7%);
      color: #ffffff;
    }
  }

  .mc-add-products-modal-close-area {
    position: relative;
    bottom: 30px;
    right: 33px;
    margin-top: 50px;
  }

  .mc-no-selected-products {
    height: 77px;
  }

  .mc-schedule-title {
    display: flex;
    align-items: center;
  }

  %mc-invoice-status {
    border-radius: 3px;
    font-size: 12px;
    margin-left: 8px;
    padding: 1px 5px;
  }
  .mc-invoice-status-draft {
    @extend %mc-invoice-status;
    background: lighten($status-draft, 5%);
  }
  .mc-invoice-status-scheduled {
    @extend %mc-invoice-status;
    background: lighten($status-scheduled, 5%);
  }

  .mc-fields-invoice-status-scheduled {
    background: lighten($status-scheduled, 5%);
  }

  .mc-create-user-notify-wrapper {
    width: 100%;
  }
  .mc-notify-message-wrapper {
    position: relative;
  }

  .mc-action-btns {
    background-color: darken($login-blue-color, 10%);
    cursor: pointer;
  }

}



/* responsive for action buttons */

@media screen and (max-width: 1700px) {
  .mc-create-invoice-modal {

    .mc-invoice-summary-action-btn {
      max-width: 60%;
    }

  }
}

@media screen and (max-width: 1460px) {
  .mc-create-invoice-modal {

    .mc-invoice-summary-action-btn {
      max-width: 80%;
    }

  }
}

@media screen and (max-width: 1270px) {
  .mc-create-invoice-modal {

    .mc-invoice-summary-action-btn {
      max-width: 100%;
      bottom: 85px !important;
      padding: 0 60px;
    }

  }
}

