.mc-setup-area {
  background-size: cover;
  box-shadow: inset 0 0 0 1000px rgba(58,120,151,.65);
  .mc-login-card {
    .boarding-btn {
      margin-top: 20px;
    }
    .mc-login-header img {
      padding: 0;
      height: 70px;
    }
  }
  .mc-login-card-body {
    position: relative;
    padding: 0 40px;

    .mc-notify-message-wrapper {
      padding-top: 20px;
    }

    .mc-tabset ul.nav-tabs {
      border-bottom: 1px solid $login-gray-color;
      padding: 10px 0;
      justify-content: center !important;
      color: #6C6A6A;
      li:first-of-type {
        &:after {
          content: '- or -';
        }
      }
      a {
        display: inline-block;
        color: #6C6A6A;
        cursor: pointer;
        border: none;
        &.active {
          color: $login-blue-color;
        }
      }
    }

    .custom-control {
      &.fill-checkbox {
        --color: $dialog-text-color;
        margin-bottom: 5px;

        .fill-control-input {
          display: none;

          &:checked ~ .fill-control-indicator {
            background-color: $dialog-text-color;
            border-color: $dialog-text-color;
            background-size: 80%;
          }
        }
        .fill-control-indicator {
          border-radius: 10px;
          display: inline-block;
          position: absolute;
          top: 4px;
          left: 0;
          width: 16px;
          height: 16px;
          border: 1px solid #aaa;
          //transition: .2s;
          background-size: 0;
          background-position: center;
          background-repeat: no-repeat;
          background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
        }

        .fill-control-description {
          font-size: 10px;
          text-transform: uppercase;

          strong {
            font-size: 13px;
          }
        }
      }
    }
    .tab-content {
      padding: 0 15px;
    }
    form {
      width: 100%;
      padding-top: 70px;
    }
  }
  }
  // enter password section
  .tab-content {
    position: relative;
  }
  .mc-login-password-fields {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 20px;
    input {
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
      padding: 20px;
      font-size: 14px;
    }
  }
  .mc-password-policy {
    padding-left: 20px;
    p {
      font-weight: 500;
    }
    .form-group {
      margin: 0;
      font-size: 14px;
    }
  }
  // generate password section
  .rule-wrapper {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
  }
  .rule-checkbox-wrapper {
    display: flex;
    flex-direction: row;
    flex: 1;
    .form-group {
      margin: 0 0.5rem 0 0;
    }
  }
  .password-wrapper {
    flex-direction: column;
  }
  .password-input-wrapper {
    justify-content: space-between;
  }

.generate-container {
  .arrows {
    .up span {
      color: $login-blue-color;
      display: block;
      transform: rotateZ(-90deg);
      position: absolute;
      left: 160px;
      cursor: pointer;
    }
    .up span::selection {
      background: none;
    }
    .down span {
      color: $login-blue-color;
      display: block;
      transform: rotateZ(-90deg);
      position: absolute;
      top: 35px;
      left: 160px;
      cursor: pointer;
    }
    .down span::selection {
      background: none;
    }
  }

  .row {
    .btn-sync {
      position: absolute;
      right: 50px;
    }

    .sync-icon {
      cursor: pointer;

      img {
        height: 25px;
        width: 25px;
        position: absolute;
        right: 69px;
        bottom: 156px;
      }
    }

    .copy-text {
      font-size: 90%;
      font-weight: 400;
      color: rgba(0, 0, 0, .56);

      a {
        cursor: pointer;
      }

      img {
        height: 20px;
        width: 20px;
        position: absolute;
        right: 235px;
      }
    }

    .form-group {
      .checkbox-input {
        left: 0 !important;
        position: absolute;
        top: 5px;
      }
    }
  }

  ul {
    padding: 0 !important;
    margin: 0;
    clear: both;
    border-bottom: none !important;
  }

  li {
    list-style-type: none;
    list-style-position: outside;
    padding: 10px;
    float: left;
  }

  input[type="checkbox"]:not(:checked),
  input[type="checkbox"]:checked {
    position: absolute;
    left: -9999%;
  }

  input[type="checkbox"] + label {
    display: inline-block;
    text-align: center;
    height: 40px;
    width: 60px;
    padding: 10px;
    cursor: pointer;
    border: 1px solid $mc-gray-border-color;
    color: $mc-gray-border-color;
    background-color: white;
    margin-bottom: 10px;
  }

  input[type="checkbox"]:checked + label {
    border: 1px solid $mc-gray-border-color;
    color: $login-blue-color;
    background-color: $light-blue-color;
  }

  input {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;

  }

  .first-row {
    margin-bottom: 15px;

    .char-class {
      position: absolute !important;
      top: 28px;
      bottom: 0;
      right: 310px;
    }

    .length-input {
      top: -8px;
      position: relative;
    }
  }


  // Responsive
  @media only screen and (max-width: $small-device) {
    .mc-setup-area {
      .mc-login-header {
        img {
          height: 60px;
        }
      }

      .mc-login-card .mc-login-card-body {
        padding: 0 30px 30px;
      }

      .mc-login-password-fields.mc-divider {
        border-right: none;
      }

      .mc-password-policy {
        p {
          width: 100%;
        }

        .mc-password-policy-requirements {
          column-count: 2;
        }
      }
    }
  }
  @media only screen and (max-width: 460px) {
    .mc-setup-area {
      .mc-login-card-body ul {
        flex-direction: column;
        align-items: center;

        li a {
          line-height: 1;
          padding-top: 5px;
          padding-bottom: 5px;
        }

        li:first-of-type {
          &:after {
            display: block;
            text-align: center;
          }
        }
      }

      .mc-input-password {
        margin-bottom: 0;
      }

      .mc-login-card .mc-login-card-body form {
        padding-top: 50px;
      }

      .mc-password-policy {
        margin-top: 10px;
      }

      .mc-login-btn-area {
        padding-top: 30px;
      }

      .mc-seepassword-btn {
        width: 17%;
      }
    }
  }

  /*#length {
    background: linear-gradient(to right, white 60%, $light-blue-color 50%);
  }*/
}






