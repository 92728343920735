@import "variables";

// Checkbox
.mat-mdc-checkbox {
  label {
    margin: 0px;
    font-family: $font-secondary;
  }
}

.mat-mdc-checkbox-ripple .mat-mdc-ripple-element,
.mat-mdc-checkbox-checked.mat-mdc-accent .mat-mdc-checkbox-background {
  background-color: $cc-secondary-color !important;
}

.mat-mdc-checkbox .mdc-checkbox__ripple {
  display: none;
}

.mat-mdc-checkbox-checked .mdc-checkbox__background,
.mat-mdc-checkbox-indeterminate .mdc-checkbox__background {
  background-color: $cc-secondary-color !important;
  border-color: $cc-secondary-color !important;
}

.mat-mdc-checkbox-frame {
  border-color: $cc-secondary-color !important;
}

.mat-mdc-card:not([class*=mat-elevation-z]) {
  box-shadow: $general-shadow;
}

.mat-bottom-sheet-container {
  padding: 0px !important;
  min-width: 40px !important;
}

.mat-mdc-row {
  overflow: visible !important;
}

@supports (-moz-appearance: none) {
  .mat-mdc-select  {
    top: -5px !important;
    position: relative;
  }
  .mat-mdc-select-value, .mat-mdc-select-arrow {
    top: -20px !important;
    position: relative;
  }
}

// Filter
.mat-expansion-panel-body {
  padding: 0px 24px !important;
}

.mat-form-field-appearance-outline .mat-mdc-form-field-flex,
.mat-form-field-appearance-outline .mat-mdc-text-field-flex {
  height: 44px;
}

.mat-mdc-form-field .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above--float-above {
  top: auto;
  margin-top: 5px;
}

.mdc-floating-label {
  top: 20px !important;
}

.mdc-floating-label--float-above{
  top: 26px !important;
  font-size: 16px;
  width: fit-content;
}

.mat-mdc-form-field-subscript-wrapper {
  height: 15px;
}

.mat-mdc-dialog-surface {
  padding: 24px;
}

mat-radio-group {
  display: flex;
  margin-left: -10px;
  margin-top: -5px;
}

.mat-mdc-radio-group ul li {
  height: 32px;
}

.mat-mdc-select-value,
.mat-mdc-select-arrow,
.mat-date-range-input,
.mat-mdc-input-element {
  position: relative;
  top: -5px;
}

.mat-expansion-indicator {
  display: none;
}

.mat-mdc-form-field-icon-suffix {
  top: -10px !important;
  right: -10px !important;
}

.mat-mdc-form-field-wrapper {
  margin: 0!important;
  padding: 0;
}

.newStyleDate {
  .mdc-floating-label--float-above {
    margin-left: 0 !important;
  }
}

.mk-mat-filter-container {
  display: flex;
  position: relative;
  padding: 15px 0px;
  background-color: $filter-bg;
  border-radius: $bottom-border-radius;

  .filter-inputs {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr) );
    column-gap: 10px;
    width: 100%;

    .mat-mdc-form-field {
      width: 100%;
      margin-bottom: -10px;
    }

    input {
      color: $primary-text-color;
    }
  }

  .date-field {
    margin-top: 0 !important;

    .mcc-fi-date-picker {
      width: 100%;

      .date-range-input {
        padding-left: 15px !important;
      }
    }

    .form-group, .input-group, .input-group input {
      width: 100%;
    }

    .form-group {
      position: relative;

      i {
        position: absolute;
        display: flex;
        flex-basis: inherit;
        justify-content: flex-end;
        margin-right: $margin-large;
        font-size: $medium-font;
        color: $mc-header-color;
        right: 0;
        top: 12px;
        z-index: 10;
        pointer-events: none;
      }
    }
  }

  .date-range-input {
    height: 42px;
  }

  .mc-range-date-label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: -7px !important;
    left: 29px !important;
  }
}

.filter-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  width: calc(100% + 50px);
  border-top: 1px solid rgba(1,21,34,0.2);
  padding: 10px;
  margin-left: -25px;

  .clear-all {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 26px;
    font-size: $small-font;
    color: $cc-primary-color !important;
    font-weight: 500;
    background-color: $white-color !important;

    &:hover {
      background-color: $white-color !important;
    }
  }

  .filter-button {
    margin-top: 3px;
    background: $cc-primary-color !important;
    color: $white-color !important;
    border-radius: $border-radius-small;
    width: 100px;
    height: 36px;
    transition: $hover-transition;
    font-size: $small-font;
    &:hover {
      background-color: darken($cc-primary-color, 10%) !important;
    }
  }
}

//Mat Select Panel
.mat-mdc-select-panel {
  width: calc(100% + 18px);
  margin-top: 0;
  margin-left: 0;
  min-width: 100px !important;
  max-width: none !important;
  padding: 0 !important;
}

.dialog-select {
  width: calc(100% + 22px);
  min-width: 100px !important;
  max-width: none !important;
}

//  Search Tooltip
.mat-mdc-tooltip {
  color: $white-color;
  font-size: $smallest-font;
  background-color: $mc-header-color;
}

// Mat Menu Item (three dot)
.mat-mdc-menu-panel {
  min-height: 50px !important;
  max-width: fit-content !important;
}

// Mat Option
mat-option {
  border-bottom: 1px solid $job-inactive;
}

mat-option:last-child {
  border-bottom: none;
}

.mat-mdc-menu-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 !important;

  .mat-mdc-menu-item {
    height: 100%;
    font-weight: 500;

    &:hover {
      background-color: $primary-light-blue-color-lightened;
    }
  }
}

.mat-column-getVATString-- div{
  display: flex;
  align-items: center;
  min-width: max-content;

  .icon-tooltip {
    margin: 0px 0px 0px $margin-small !important;

    img {
      margin: 0px;
    }
  }
}
.cdk-overlay-container {
  z-index: 9999;
}

//Mat dialog

.dialog {
  .mat-mdc-dialog-content {
    height: calc(100% - 130px);
    padding: 5px 0 !important;
  }

  &_title {
    font-size: 16px;
    font-weight: 700;
    color: #333867;
    margin-bottom: 10px;
  }

  &_header{
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    margin-bottom: 15px;

    &:after {
      content: '';
      position: absolute;
      height: 1px;
      background: rgba(1,21,34,0.2);
      bottom: 0;
      width: calc(100% + 60px);
      margin-left: -35px;
    }

    &_title {
      font-family: Maven Pro;
      font-size: 20px;
      font-weight: 800;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      color: #53589B;
    }

    &_icon {
      position: absolute;
      right: 0;
      top: 0px;
      cursor: pointer;
    }
  }
  &_footer {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    margin-top: 15px;
    padding-top: 20px;
    width: calc(100% - 10px);
    margin-left: 5px;

    &:after {
      content: "";
      position: absolute;
      height: 1px;
      background: rgba(1, 21, 34, 0.2);
      top: 0;
      right: -30px;
      left: -30px;
    }
  }
}

.dialog .mat-mdc-dialog-container{
  overflow-x: hidden !important;
}

//mat form fields
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: $cc-primary-color;
}

.mdc-text-field--outlined .mdc-notched-outline__leading,
.mdc-text-field--outlined .mdc-notched-outline__notch,
.mdc-text-field--outlined .mdc-notched-outline__trailing {
  border-color: rgb(212, 212, 212) !important;
}

.mdc-text-field--outlined.mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--outlined.mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--outlined.mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: $cc-primary-color !important;
}

.mat-mdc-form-field.mat-focused .mdc-floating-label--float-above {
  color: $cc-primary-color !important;
}

.mat-mdc-form-field.mat-focused .mat-mdc-select-arrow {
  color: $cc-primary-color !important;
}

.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none !important;
  margin-left: -2px;
}

// Mat datepicker
.mat-calendar-header, .mat-calendar-content {
  padding: 0 !important;
}

.mat-calendar-body-selected {
  background-color: $cc-primary-color;
}

.mat-calendar-body-in-range::before {
  background: $calendar-range-bg;
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: none;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background: $calendar-range-bg;
}

.mat-calendar-body-today:not(.mat-calendar-body-selected) {
  background-color: $cc-primary-color !important;
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: $bg_color;
  border: none;
}

.mat-datepicker-toggle-active {
  color: $cc-primary-color;
}

.date-range {
  .mat-date-range-input-separator,
  input
  {
    cursor: context-menu !important;
  }
}

.mat-datepicker-toggle{
  top: 11px;
  right: 10px;
  fill: #53589B;
  position: relative;
}

