// Use this file for global classes

.reset {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: inherit;
  vertical-align: baseline;
}

.mc-button {
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  border:none;
  outline: none;
  color: $bg_color;
  padding: 5px 30px;
  &:focus{
    outline: none;
  }
}

.mc-link{
  border:none;
  outline: none;
  padding: 0;
  color: $login-blue-color;
  background: none;
  margin-bottom: 10px;
  font-weight: 500;
  &:hover {
    text-decoration: underline;
  }
  &:focus{
    outline: none;
  }
}

.mc-go-back {
  position: absolute;
  top: 5px;
  left: 8px;
  font-size: 14px;
  color: $login-blue-color;
  cursor: pointer;
}

.mc-logo {
  display: block;
  height: 70px;
  margin: 20px auto;
}

.mc-alert-error-input {
  border-color: $error_color;
}

.mc-cursor-pointer {
  cursor: pointer;

  &:hover {
    color: $blue-filter-color
  }
}

.mc-cursor-pointer-radio-button {
  cursor: pointer;
}

.capitalize {
  display: inline-block;
  margin: 0;

  &:first-letter {
    text-transform: capitalize;
  }
}

.mc-text-capitalize {
  text-transform: capitalize;
}

.mc-submit-color {
  color: $cc-primary-color;
}

.mc-submit-button {
  background: #35b1f1;
  border: none;
  outline: 0;
}

.mc-gray-background {
  background: $mc-gray-background
}

/*Vertical scrollbar*/
.mc-scrollbar-vertical::-webkit-scrollbar {
  width: 6px;
  background-color: $mc-gray-background;
}

.mc-scrollbar-vertical::-webkit-scrollbar-thumb {
  border-radius: 15px;
  background-color: $mc-silver-border-color;

  &:hover {
    background-color: $mc-gray-border-color;
    cursor: pointer;
  }
}

.mc-property-title {
  padding: 5px 0;
  color: $mc-gray-line;
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  flex-basis: 100px;
}

.mc-field-width-15 {
  width: 15%;
}
.mc-field-width-7 {
  width: 7%;
}
.mc-field-width-30 {
  width: 30%;
}
.custom-select.mc-field-width-10 {
  width: 10%;
}


/*Placeholder style*/
::-webkit-input-placeholder {
  text-transform: initial;
}

:-moz-placeholder {
  text-transform: initial;
}

::-moz-placeholder {
  text-transform: initial;
}

:-ms-input-placeholder {
  text-transform: initial;
}

.mc-input-field-invalid {
  border: 2px solid $error_color !important;
}

.mc-border-bottom {
  border-bottom: 4px solid $black !important;
}
.mc-border-bottom-light {
  border-bottom: 1px solid #dee2e6;
}

.mc-border-top {
  border-top: 4px solid $black !important;
}

.mc-reporting-table-td-gap {
  padding: 5px !important;
}

.mc-text-click {
  color: $mc-blue-text-color;
  cursor: pointer;
  font-weight: 500;

  &:hover {
    color: darken($mc-blue-text-color, 10%);
  }
}

.mc-click-not-allow{
  background: $gray-500;
  color: $white;
  cursor: not-allowed;
  border: none;

  &:focus {
    outline: none;
  }
}

.page-title {
  font-weight: 800;
  font-size: 24px;
  color: $cc-primary-color;
  text-transform: uppercase;
}

.actions-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  box-shadow: $general-shadow;
  border-radius: $border-radius-small;
  min-height: 60px;
  user-select: none;
  margin-top: $margin-large;
  margin-bottom: $margin-medium;
  padding: 0px 20px;
  height: 64px;
  background: $filter-bg;

  span {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: $cc-subtitle-color;

  }

  .actions-wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  .icon-tooltip {
    width: 30px;

    img {
      width: 30px;
    }
  }

  .mc-transaction-status {
    display: flex;
  }
}

.actions-title {
  display: flex;
  align-items: center;
  background-color:$mc-header-color;
  height: 60px;
  padding: 0px 20px;
  margin-left: -20px;
  border-radius: $top-bottom-right-border-radius;

  & span {
    color: white;
  }
}

.system-action-btn {
  color: $white !important;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
  padding: 8px 15px;
  font-size: 16px;
  transition: background 200ms cubic-bezier(0.25, 0.8, 0.25, 1),box-shadow 180ms cubic-bezier(0.4, 0, 0.2, 1);
  outline: none;
  background: $cc-action-button-color;
  border-radius: 4px;
  height: 44px;
  font-weight: 500;
  line-height: 19px;


  &.dark-system-action-btn {
    background-color: $mc-header-color;

    &:hover {
      background-color: lighten($mc-header-color, 10%) !important;
    }

  }

  &.light-system-action-btn {
    background-color: $mc-white-color;
    color: $mc-header-color !important;
    border: 1px solid $mc-header-color;

    &:hover {
      background-color: darken($mc-white-color, 10%) !important;
    }
  }

  &:focus {
    border: none;
    outline: none;
  }

  &:hover {
    background-color: darken($cc-action-button-color, 10%) !important;
  }

  i {
    font-size: 16px;
    margin-right: 8px;
  }
}

.system-action-btn-disabled {
  background-color: #cccccc8f !important;
  color: $white !important;
  display: flex;
  align-items: center;
  border: none;
  border-radius: 5px;
  padding: 8px 15px;
  font-size: 16px;
  font-weight: 400;
  transition: background 200ms cubic-bezier(0.25, 0.8, 0.25, 1),box-shadow 180ms cubic-bezier(0.4, 0, 0.2, 1);
  outline: none;
  height: 44px;
  pointer-events: none;

  i {
    font-size: 16px;
    margin-right: 8px;
  }
}

.primary-btn,
.secondary-btn {
  font-family: $font-secondary !important;
  width: 100px;
  height: 36px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  padding: 0 10px !important;
}

.primary-btn {
  background: $dialog-text-color !important;
  color: $white-color !important;
  &:disabled {
    opacity: 0.5;
    color: $white-color !important;
  }
}

.secondary-btn {
  border: 1px solid $dialog-text-color !important;
  color: $dialog-text-color !important;
}

.icon-text-table-cell  {
  display: flex;
  gap: 10px;
  align-items: center;
}

.divider {
  display: block;
  width: 100%;
  height: 1px;
  background-color: rgba(214, 215, 225, 1);
  margin: $margin-large 0;
}
