// Add consumers page
.mc-add-consumer-modal {
  .modal-dialog {
    max-width: 1200px;
  }

  .mc-active {
    color: $login-blue-color;
    border-bottom: 3px solid $login-blue-color;
  }

  .mc-active-background {
    background: $login-blue-color;
  }

  .mc-semi-active {
    color: lighten($login-blue-color, 15%);
  }

  .mc-semi-active-background {
    background-color: lighten($login-blue-color, 15%);
  }

  .mc-create-user-modal-header {
    display: block;
    padding-left: 0;
    padding-right: 0;
    border-bottom: 0;

    .mc-create-user-entity-path {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: $mc-blue-text-color;
      font-size: 20px;
      font-weight: 500;

      span {
        display: flex;
        align-items: center;

        &::after {
          content: '\f105';
          color: #8d969d;
          margin-left: 5px;
          margin-right: 5px;
          font-weight: 600;
          font-family: 'Font Awesome\ 5 Free', serif;
          font-size: 14px;
        }

        &:last-child::after {
          content: '';
        }
      }
    }
  }

  button.close.mc-consumers-close-btn {
    @extend %modalCloseBtn;
  }

  .modal-body {
    background: $mc-gray-background;
    border-top: 3px solid $mc-gray-line;
  }

  .modal-content {
    border-radius: 0;
    padding: 10px 50px 50px 50px;
    position: relative;
  }

  .mc-add-consumers-form {
    position: relative;
    justify-content: center;

    .mc-notify-message-wrapper {
      top: 10px;
    }

    .mc-save-consumers-btn {
      text-align: right;
      padding: 0 20px;
    }
  }

  .extended-info {
    border-left: 1px solid $mc-gray-line;
  }

  .mc-consumers-summary-title {
    color: $login-blue-color;
    padding-left: 15px;

    .fa-pen {
      cursor: pointer;
    }

    h6 {
      display: inline-block;
    }
  }

  .mc-consumers-summary-basic-info {
    div:first-child {
      font-size: 12px;
      font-weight: 600;
      text-transform: uppercase;
    }

    div:last-child {
      font-size: 16px;
    }
  }

  .mc-consumers-label {
    display: block;
    font-size: 14px;
    font-weight: 500;
  }

  .mc-payment-pause {
    display: flex;
    justify-content: space-between;
    align-items: center;

    label {
      padding-right: 10px;
      margin-bottom: 0;
    }

    input:first-of-type {
      margin-right: 30px;
    }
  }

  .mc-consumers-summary-value {
    span:first-child {
      font-size: 14px;
    }
  }

  .mc-consumers-summary-btn {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    z-index: 9;
  }

  .mc-custom-attributes-summary-view {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
  }

}

// Edit consumers page


.mc-edit-consumers {
  margin-bottom: 50px;

  .mc-edit-consumers-general {
    background: $mc-gray-background;
    border-radius: 10px;
    padding: 20px 70px;
    min-height: 700px;
  }

  .mc-edit-consumers-general-title {
    border-bottom: 1px solid $mc-gray-line;
    font-size: 22px;
    font-weight: 500;
    padding-bottom: 10px;
    text-align: center;

    span {
      font-size: 25px;
      color: lighten($mc-header-color, 5%);
    }
  }

  .mc-edit-consumers-basic-info {
    padding: 20px 0 20px 0;
    //border-bottom: 1px solid lighten($mc-gray-line, 10%);
  }

  .mc-edit-consumers-basic-info-link:not([href]) {
    font-size: 20px;
    color: $login-blue-color;
    cursor: pointer;
    padding-bottom: 10px;
    position: relative;
    padding-right: 30px;
    font-weight: 500;

    &:hover {
      color: darken($login-blue-color, 10%);
    }

    .fa-pen {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .mc-consumers-view-history-btn:not([href]) {
    font-size: 15px;
    font-weight: 500;
    color: $login-blue-color;
    cursor: pointer;
    text-decoration: underline;

    &:hover {
      color: darken($login-blue-color, 10%);
      text-decoration: underline;
    }
  }

  .mc-edit-consumers-block {
    margin: 0;
  }

  .mc-edit-consumers-preview-data {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
  }

  .mc-edit-consumers-preview-field-name {
    color: $mc-gray-line;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    flex-basis: 160px;
  }

  .mc-edit-consumers-preview-field-value {
    font-size: 16px;
    flex: 1;
    padding-left: 10px;
  }

  // Hide Search in filter field
  .mc-edit-consumers-paymentInfo {
    .mc-filter-wrapper {
      padding-top: 15px;
      padding-bottom: 15px;
    }
    .mc-input-field-wrapper {
      display: none;
    }
    .mc-search-top {
      top: 60px;
    }


  }

}

.mc-edit-consumers-modal-header {
  .mc-edit-consumers-general-title {
    font-size: 22px;
    font-weight: 500;

    span {
      font-size: 25px;
      color: lighten($mc-header-color, 5%);
    }
  }


}
