/*Mc Entity profile wrapper*/
.mc-entity-profile-wrapper {

  .mc-entity-profile-content {
    background: $personal-info-box;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;

    .pause-title-color {
      font-weight: 500;
      text-transform: uppercase;
      color: $mc-gray-border-color;
      padding-top: 10px;
      margin-left: 10px;
      border-top: 2px solid $mc-gray-border-color;
    }

    .transaction-description-info {
      text-transform: none;
    }


    .mc-entity-profile-title {
      color: $title-color;
      margin-bottom: 12px;
    }

    .inherit-parent {
      color: $mc-gray-border-color;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 10px;
    }

    .entity-image {
      width: 100%;
      max-width: 200px;
      max-height: 200px;
      border: 1px solid lightgrey;
      display: block;
    }

    .set-font {
      font-size: 12px;
      color: $mc-gray-border-color;
      font-weight: 600;
      padding-right: 30px;
    }

    .position-top {
      margin-top: -1px;
      margin-left: 0;
    }

    .right-col-position {
      margin-top: -1px;
      margin-left: -30px;
    }

    hr {
      margin-left: 8px;
    }

    .mc-notify-wrapper {
      position: relative;
      margin-bottom: 35px;
      margin-left: 8px;
      top: -10px;
      //margin-bottom: 5.3%;
    }
  }

  .mc-pen-icon {
    font-size: 12px;
    padding-left: 7px;
    cursor: pointer;
  }

  .mc-attributes {

    padding: 0 8px;

    .mc-table-local-attributes-wrapper {
      overflow: auto;

      .mc-table-local-attributes {
        & * {
          padding: 3px;
        }
        .mc-table-header-local-attributes {
          border-bottom: 2px solid $gray-500;
        }
      }
    }

    .mc-table-inherited-attributes-wrapper {
      overflow: auto;

      .mc-table-inherited-attributes {
        & * {
          padding: 3px;
        }

        .mc-table-header-inherited-attributes {
          border-bottom: 2px solid $gray-500;
        }
      }
    }
  }

  // Hide Search in filter field
  .mc-filter-wrapper{
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .mc-input-field-wrapper {
    display: none;
  }

  .mc-search-top {
    top: 60px;
  }

}

// basic-info modal
.mc-edit-entity-basic-info-modal {
  .modal-dialog {
    max-width: 1000px;

    .modal-content {
      border-radius: 0;
      .modal-header {
        border-bottom: none;
        padding-bottom: 0;
        .close {
          font-size: 60px;
          font-weight: 100;
          outline: none;
          padding: 0 10px;
          color: $mc-gray-border-color;
        }
      }
      .modal-body {
        padding: 0 9% 3% 9%;
        #modal-basic-title {
          margin-bottom: 0;
          border-bottom: 3px solid $mc-gray-border-color;
        }
        .mc-notify-wrapper-modal-basic-info {
          position: relative;
          top: -30px;
          margin-bottom: 1.3%;
        }
        .entity-form {
          background: $personal-info-box;
          padding: 5%;
        }
        label {
          margin-bottom: 1%;
          color: $login-label-color;
        }
        input, textarea {
          border: 1px solid $login-input-color;
          -webkit-border-radius: 0;
          -moz-border-radius: 0;
          border-radius: 0;
          //padding: 10px;
          font-size: 14px;
        }
        textarea {
          resize: none;
        }
        .hide-margin {
          margin-bottom: 0;
        }
        .gray-text-info {
          font-size: 13px;
          color: $mc-gray-border-color;
        }
        .required-input {
          color: $mc-red-color-text;
        }
        .mc-confirm-btn {
          background-color: $mc-blue-text-color;
          color: $bg_color;
          padding: 4px 25px;
          float: right;
          border: none;
          outline: none;
          -webkit-border-radius: 50px 50px 50px;
          -moz-border-radius: 50px 50px 50px;
          border-radius: 50px 50px 50px;
        }
        .mc-disable-btn {
          padding: 4px 25px;
          float: right;
          border: none;
          outline: none;
          -webkit-border-radius: 50px 50px 50px;
          -moz-border-radius: 50px 50px 50px;
          border-radius: 50px 50px 50px;
          background-color: $login-gray-color !important;
          color: $bg_color;
        }
      }
    }
  }
}

// attributes modal
.mc-edit-entity-attributes-modal {
  .modal-dialog {
    max-width: 1250px;

    .modal-content {
      border-radius: 0;
      .modal-header {
        border-bottom: none;
        padding-bottom: 0;
        .close {
          font-size: 60px;
          font-weight: 100;
          outline: none;
          padding: 0 10px;
          color: $mc-gray-border-color;
        }
      }
      .modal-body {
        padding: 0 9% 3% 9%;
        #modal-basic-title {
          margin-bottom: 0;
          border-bottom: 3px solid $mc-gray-border-color;
        }
      }
    }
  }
}

// password-policy modal
.mc-edit-entity-password-policy-modal {
  .modal-dialog {
    max-width: 1250px;

    .modal-content {
      border-radius: 0;
      .modal-header {
        border-bottom: none;
        padding-bottom: 0;
        .close {
          font-size: 60px;
          font-weight: 100;
          outline: none;
          padding: 0 10px;
          color: $mc-gray-border-color;
        }
      }
      .modal-body {
        padding: 0 9% 3% 9%;
        #modal-basic-title {
          margin-bottom: 0;
          border-bottom: 3px solid $mc-gray-border-color;
        }
        .mc-notify-wrapper-modal-password-policy {
          position: relative;
          top: -30px;
          margin-bottom: 1.3%;
        }
      }
    }
  }
}

.enable-icon {
  margin-left: 5px;
  border-radius: 10px;
  border: 1px solid #35B1F1;
  outline: navajowhite;
  padding: 0 4px !important;
  font-size: 13px;
  background: #35B1F1;
  color: $mc-white-background;
}

.disable-icon {
  margin-left: 5px;
  border-radius: 10px;
  border: 1px solid gray;
  outline: navajowhite;
  padding: 0 4px !important;
  font-size: 13px;
  background: lightgray;
  color: gray;
}

.info-icon {
  border-radius: 10px;
  border: 1px solid #bad8e8;
  outline: navajowhite;
  padding: 0 6px !important;
  font-size: 13px;
  font-weight: bold;
  background: #bad8e8;
  color: #35B1F1;
  cursor: pointer;
}

.description-attribute .tooltip-inner {
  background-color: $mc-info-btn-color;
  font-size: 125%;
}
.description-attribute .arrow::before {
  border-left-color: $mc-info-btn-color;
}

.fa-share-square {
  margin-top: -2px;
}

.fa-star {
  margin-top: -2px;
  margin-left: -9px;
}

.mc-pen-icon {
  font-size: 14px;
}

.mc-entity-action-btn {
  font-size: 16px;
  cursor: pointer;
}

.mc-entity-action-btn {
  font-size: 16px;
  cursor: pointer;
}

.mc-header-text {
  font-size: 12px;
  color: #b6b8ba;
  font-weight: 600;
}

.attributes-title-color {
  color: $mc-gray-border-color;
}

.mc-select-background {
  background: $table-selected-row;
}

.mc-blue-text {
  color: $login-blue-color;
}

.mc-float-right {
  display: flex;
  justify-content: flex-end;
  text-align: right;
  width: 100%;
}

.entity-basic-info-title{
  font-family: $font-secondary;
  font-size: 16px;
  font-weight: 700;
  line-height: 18.8px;
  text-align: left;
  color: $cc-action-button-color;
}
