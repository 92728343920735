.mc-transactions-table-description-column {
  width: 700px;
}

.mc-transaction-profile {

  .content-wrapper {

    .content-header {
      display: flex;

      .modal-title {
        padding-left: 10px;
      }

    }

    .content-body {

      .mc-transaction-notify-message {
        height: 30px;
        width: auto;
      }

      .mc-transaction-status-label {
        display: flex;
        background-color:#E9F5FC;
        border-radius:0 4px 4px 0;
        border-left: 8px solid #36B1F1;
        margin-bottom: 20px;
        height: 70px;

        h6 {
          font-weight: 700;
          text-transform: uppercase;
          padding-left: 25px;
          padding-top: 27px;
        }

        .mc-transaction-status {
          padding-left: 20px;
          padding-top: 25px;

          .icon-tooltip {
            position: relative;
            display: inline-block;
          }
          .icon-tooltip .icon-tooltip-text {
            visibility: hidden;
            width: 150px;
            background-color: #FFFFFF;
            color: #000000;
            text-align: center;
            border-radius: 4px;
            padding: 8px 4px;
            position: absolute;
            z-index: 1;
            top: 150%;
            left: 70%;
            margin-left: -60px;
            font-size: 12px;
            box-shadow: 0 0 12px rgba(0,0,0,0.2);
          }
          .icon-tooltip .icon-tooltip-text:after {
            content: "";
            position: absolute;
            bottom: 100%;
            left: 35%;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: transparent transparent white transparent;
          }
          .icon-tooltip:hover .icon-tooltip-text {
            visibility: visible;
          }
          .icon-tooltip:hover .icon-tooltip-hidden {
            visibility: hidden;
          }
          img {
            margin-top: -5px;
            padding-right: 5px;
            &:first-child {
              width: auto;
              height: 24px;
            }
            width: auto;
            height: 19px;
          }
        }
      }

      .info-data {
        margin-bottom: 30px;
        .title {
          font-weight: 600;
          font-size: 14px;
        }
        .mc-transaction-profile-left-column {
          background-color: #f8f8f8;
          min-height: 100%;
          border-radius:0 4px 4px 0;
          margin: 0 15px;
          padding: 10px;
        }
        .mc-transaction-profile-right-column {
          background-color: #E9F5FC;
          border-radius:0 4px 4px 0;
          min-height: 100%;
          min-width: 38.5%;
          padding: 20px;
        }
      }

      .mc-transaction-history-heading {
        border-bottom: 1px solid #dee2e6;
        max-width: 98%;

        i {
          padding-right: 10px;
        }

        h5 {
          text-transform: uppercase;
        }
      }

      .mc-transaction-profile-buttons {
        text-align: right;
        margin-top: 10px;
      }
      }

    .mc-description-bold {
      .description {
        font-weight: 600;
        max-width: 50%;
        flex: 0 0 50%;
      }
      .title {
        max-width: 50%;
        flex: 0 0 50%;
      }
    }
    .mc-transaction-money-col {
      .description {
        max-width: 50%;
        flex: 0 0 50%;
        text-align: right;
      }
      .title {
        max-width: 50%;
        flex: 0 0 50%;
      }
    }
    }

  }

