/*Price adjustment wrapper*/
$input-width: 140px;

.mc-price-adjustment-wrapper {
  position: relative;

  /*Clear amount button*/
  .mc-clear-amount-button {
    position: absolute;
    width: fit-content;
    color: $mc-blue-text-color;
    font-size: 12px;
    text-align: left;
  }

  /*Amount wrapper*/
  .mc-amount-wrapper {
    width: $input-width;
    float: right;
  }

  /*Additional discount checkbox*/
  .mc-additional-discount-checkbox {
    width: 12px;
    height: 12px;
  }

  /*Additional discount text*/
  .mc-additional-discount-text {
    font-size: 14px;
  }

  .mc-select-blue {
    background-color: $light-blue-color;
  }

  .mc-select-gray {
    background-color: $mc-table-border-color;
  }

  .mc-invalid-additional-discount {
    padding-top: 13px;
    padding-right: calc(#{$input-width} + 5px);
  }

}/*Price adjustment wrapper /end*/
