// Date picker modal
.mc-date-picker-modal {
  text-align: center;

  .modal-dialog {
    max-width: 1000px;

    .modal-content {
      border-radius: 0;
      .modal-header {
        border-bottom: none;
        padding-bottom: 0;
        .close {
          font-size: 60px;
          font-weight: 100;
          outline: none;
          padding: 0 10px;
          color: $mc-gray-border-color;
        }
      }
      .modal-body {
        padding: 10% 13% 15%;

        .mc-notify-wrapper-date {
          position: relative;
          top: -74px;
        }
        .label-from {
          position: relative;
          left: -62px;
          font-weight: bold;
        }
        .clear-date-from {
          position: relative;
          right: -62px;
          font-size: 15px;
          color: $mc-blue-text-color;
          cursor: pointer;
          &:hover {
            color: #0697dc;
          }
        }
        .label-till {
          position: relative;
          left: -70px;
          font-weight: bold;
        }
        .clear-date-till {
          position: relative;
          right: -70px;
          color: $mc-blue-text-color;
          font-size: 15px;
          cursor: pointer;
          &:hover {
            color: #0697dc;
          }
        }
        .mc-confirm-btn {
          background-color: $mc-blue-text-color;
          color: $bg_color;
          padding: 4px 25px;
          float: right;
          position: relative;
          right: 60px;
          border: none;
          outline: none;
          -webkit-border-radius: 50px 50px 50px;
          -moz-border-radius: 50px 50px 50px;
          border-radius: 50px 50px 50px;
        }
      }
    }
  }
}
