// Add/edit bankAccount
.mc-bank-account {
  .modal-dialog {
    max-width: 700px;

    .modal-content {
      border-radius: 0;
      padding: 20px 30px 30px !important;
      .modal-header {
        border-bottom: none;
        padding-top:  5px;

        .mc-bank-account-modal-header {
          display: block;
          border-bottom: 0;
          padding-left: 0 !important;
          padding-bottom: 10px;
        }
        .close {
          font-size: 60px;
          font-weight: 100;
          outline: none;
          padding: 0 10px;
          color: $mc-gray-border-color;
        }
      }
      .modal-body {
        padding: 0 !important;
        #modal-basic-title {
          margin-bottom: 0;
          border-bottom: 3px solid $mc-gray-border-color;

        }
        .mc-notify-wrapper {
          position: relative;
          top: -15px;
          margin-bottom: 19px;
        }
        .bank-account-form {
          background: $personal-info-box;
          padding: 5%;
          border-top: 3px solid $mc-gray-border-color;

          .align-label {
            display: flex;
            align-items: center;
          }
        }

        label {
          margin-bottom: 1%;
          display: block;
          font-size: 14px;
          font-weight: 500;
        }

        input {
          border: 1px solid $login-input-color;
          -webkit-border-radius: 0;
          -moz-border-radius: 0;
          border-radius: 0;
          font-size: 14px;
        }

        .hide-margin {
          margin-bottom: 0;
        }

        .gray-text-info {
          font-size: 13px;
          color: $mc-gray-border-color;
        }

        .required-input {
          color: $mc-red-color-text;
        }

        .mc-confirm-btn {
          background-color: $mc-blue-text-color;
          color: $bg_color;
          padding: 4px 25px;
          float: right;
          border: none;
          outline: none;
          -webkit-border-radius: 50px 50px 50px;
          -moz-border-radius: 50px 50px 50px;
          border-radius: 50px 50px 50px;
        }
      }
    }
  }
}
