.mc-incomplete-profile-card {
  &.card{
    border-radius: 10px;
  }

  .mc-incomplete-profile-header {
    background-color: darken($mc-gray-background, 2%);
    border-radius: 10px;
    img {
      padding: 30px 0 40px;
      box-sizing: content-box;
      height: 70px;
    }
    div {
      font-size: 20px;
      font-weight: 500;
    }
  }

  .mc-incomplete-profile-back-to-login {
    .mc-go-back {
      font-size: 14px;
      cursor: pointer;
      padding: 5px;
    }
  }

  .mc-create-user-notify-wrapper {
    .mc-notify-message-wrapper {
      padding: 0 20px;
    }
  }

  .mc-user-first-name,
  .mc-user-last-name,
  .mc-user-gender {
    .mc-new-user-field-names {
      &::after {
        @extend %mandatoryStar;
      }
    }
  }

  .mc-user-gender {
    padding: 0;

    label {
      margin-right: 10px;
      font-size: 14px;
    }

    & > div {
      margin-bottom: 10px;
    }
  }

  .mc-new-user-image-area {
    input[type="file"] {
      display: none;
    }
  }

  .mc-new-user-image {
    margin-top: 10px;
    min-width: 100%;
    height: 210px;
    cursor: pointer;
    background-color: $white;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-origin: content-box;
  }

  .mc-new-user-image-area .mc-upload-image-btn {
    display: inline-block;
    font-size: 12px;
    color: $mc-blue-text-color;
    cursor: pointer;
    padding: 5px 0 5px 5px;
    float: right;

    &:hover {
      color: darken($mc-blue-text-color, 10%);
    }
  }

  .mc-next-save-btn {
    background: $mc-blue-text-color;
    border: none;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 20px;

    &:focus {
      box-shadow: none;
    }

    &:hover {
      background: darken($mc-blue-text-color, 15%);
    }
  }
  .mc-error-field {
    border-color: $error_color;
  }
  //.mc-create-user-genders {
  //  &::before{
  //    border-color: $error_color;
  //  }
  //}

  .mc-create-user-genders {
    [type=radio]:not(:checked)+label:before {
      border-color: $error_color;
    }
  }






}
