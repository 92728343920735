/*MC job action wrapper*/
.mc-job-action-wrapper {
  .mc-action {
    display: inline-block;
    width: 30px;
    height: 30px;
    background-color: $job-inactive;

    //.mc-action-hover {
    //
    //  &:hover {
    //    cursor: pointer;
    //    opacity: .8;
    //  }
    //}

    &._inactive {
      background-color: $job-inactive;
      opacity: .6;
    }

    &._run {
      background-color: $job-start;

      &:hover {
        cursor: pointer;
        opacity: .8;
      }
    }

    &._pause {
      background-color: $job-pause;

      &:hover {
        cursor: pointer;
        opacity: .8;
      }
    }

    &._resume {
      background-color: $job-resume;

      &:hover {
        cursor: pointer;
        opacity: .8;
      }
    }

    &._unschedule {
      background-color: $job-stop;

      &:hover {
        cursor: pointer;
        opacity: .8;
      }
    }

    .mc-action-icon {
      width: 12px;
      margin: 0 auto;
      position: relative;
      display: block;
      top: 10px;

      &._pause {
        top: 9px;
        width: 9px;
      }

      &._unschedule {
        width: 8px;
        top: 11px;
      }

      &._resume {
        top: 9px;
      }
    }
  }
}
