// Create-edit product modal
.mc-create-edit-product {
  .modal-dialog {
    max-width: 1200px;

    button.close.mc-product-close-btn {
      @extend %modalCloseBtn;
    }

    .modal-content {
      padding: 10px 15px 15px;
      position: relative;
      border-radius: 0 !important;
      .modal-header {
        border: none !important;
      }
      .mc-create-product-modal-header {
        display: block;
        border-bottom: 0;
        padding: 15px 30px;
      }

      .modal-body {
        padding-top: 0;
        .mc-product-label {
          display: block;
          font-size: 14px;
          font-weight: 500;
        }
        //padding: 0 9% 3% 9%;
        #modal-basic-title {
          margin-bottom: 0;
          border-bottom: 3px solid $mc-gray-border-color;
        }
        .mc-notify-wrapper {
          position: relative;
          top: -30px;
          margin-bottom: 1.3%;
        }
        .product-form {
          background: $personal-info-box;
          padding: 5%;
          border-top: 3px solid $mc-gray-border-color;

          .align-label {
            display: flex;
            align-items: center;
          }

          .label-position {
            word-break: break-word;
          }

          .euro-sign {
            font-size: 14px;
            position: absolute;
            top: 10px;
            right: 0;
          }
        }
        label {
          margin-bottom: 1%;
          color: $login-label-color;
        }
        input, textarea {
          border: 1px solid $login-input-color;
          -webkit-border-radius: 0;
          -moz-border-radius: 0;
          border-radius: 0;
          //padding: 10px;
          font-size: 14px;
        }
        textarea {
          resize: none;
        }
        .hide-margin {
          margin-bottom: 0;
        }
        .gray-text-info {
          font-size: 13px;
          color: $mc-gray-border-color;
        }
        .required-input {
          color: $mc-red-color-text;
        }
        .mc-confirm-btn {
          background-color: $mc-blue-text-color;
          color: $bg_color;
          padding: 4px 25px;
          float: right;
          border: none;
          outline: none;
          -webkit-border-radius: 50px 50px 50px;
          -moz-border-radius: 50px 50px 50px;
          border-radius: 50px 50px 50px;
        }
        .mc-disable-btn {
          padding: 4px 25px;
          float: right;
          border: none;
          outline: none;
          -webkit-border-radius: 50px 50px 50px;
          -moz-border-radius: 50px 50px 50px;
          border-radius: 50px 50px 50px;
          background-color: $login-gray-color !important;
          color: $bg_color;
        }
      }
    }
  }
}

/*Edit product wrapper*/
.mc-edit-product-wrapper {
  height: fit-content;
  background-color: $mc-gray-background;
  border-radius: 10px;

  .mc-notify-wrapper {
    position: relative;
    top: -4px;
    margin-bottom: 37px;
  }

  .mc-edit-product-title {
    border-bottom: 3px solid $mc-gray-line;
  }
}
/*Edit product wrapper /end*/

#mc-create-edit-product-btn {
  color: $mc-blue-text-color;
  cursor: pointer;
  font-weight: 500;

  &:hover {
    color: darken($mc-blue-text-color, 10%) !important;
  }
}

