.mc-create-role-modal {
  section.container {
    position: relative;

    form {
      height: 460px;
    }
  }
  .modal-content {
    border-radius: 0 !important;
    left: 100px;
  }


  .mc-create-role-summery-wrapper {
    height: 500px;
    overflow-y: scroll;
  }



  .modal-dialog {
    min-width: 1180px;
  }

  /*Create role wrapper*/
  .mc-role-create-wrapper {

    /*Close modal button*/
    .mc-close-button {
      font-size: -webkit-xxx-large;
    }
    /*Modal header*/
    .mc-role-modal-header {
      padding: 15px 35px 5px;
        .close {
          margin: 0;
          font-weight: 100;
          font-size: 60px;
          position: absolute;
          right: 10px;
          top: 0;
          color: #b6b8ba;
          padding: 0 !important;
        }

      button:focus {
        outline: none;
      }

    }

    /*Modal body*/
    .mc-create-role-modal-body {
      padding: 40px 20px;
      border-top: 3px solid $mc-gray-line;
      border-bottom: 3px solid $mc-gray-line;

      .mc-create-role-modal-edit-body {
        height: 500px;
      }

    }


    .mc-header-wrapper {
      padding-bottom: 40px;
    }

    .mc-active {
      border-bottom: 3px solid $submit-button-color;

      .mc-selected-step {
        background: $submit-button-color;;
        color: $white;
      }
    }

    .mc-semi-active-role {
      color: lighten($login-blue-color, 15%);
      border-bottom: 3px solid white;
    }


    .mc-selected-step {
      background: $submit-button-color;;
      color: $white;
    }
  }

  /*Search permission icon*/
  .mc-search-icon {
    position: relative;
    left: -25px;
    color: $mc-gray-line;
  }

  /*Search permission*/
  .mc-search-permissions {
    width: 350px;
  }

  /*Permission number*/
  .mc-permissions-number {
    font-size: 11px;
    background: $submit-button-color;
    color: $white;
    padding: 2px 5px;
    border-radius: 50%;
  }

  /*Permission list*/
  .mc-permissions-list, .mc-permissions-module-wrapper {
    height: 300px;
    overflow-y: scroll;
  }

  /*Selected permission list*/
  .mc-selected-permissions-list {
    height: 436px !important;
    overflow-y: scroll;


    .mc-selected-permissions-title {
      color: $submit-button-color;
      border-bottom: 3px solid $mc-table-border-color;
    }
  }






  .mc-create-role-buttons-wrapper {
    width: fit-content;
    top: 0;
    float: right;

    button {
      background-color: $submit-button-color;
      border: none;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.mc-created-roles {
  background-color: $table-selected-row;
  margin: 0 20px;
  padding: 5px 15px;
}


.mc-edit-role-wrapper {
  padding: 0 35px;
}
.mc-role-label {
  display: block;
  font-size: 15px;
  font-weight: 500;
}
.mc-action-buttons {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 9;
}

.mc-disabled-link {
  color: #d1d1d1 !important;
  &:hover {
    color: #d1d1d1 !important;
  }
}


/*Edit role wrapper*/
.mc-role-edit-wrapper {
  height: fit-content;
  background-color: $mc-gray-background;
  border-radius: 10px;

  .mc-edit-role-title {
    border-bottom: 3px solid $mc-gray-line;
  }

}

/*Edit role wrapper /end*/

.mc-edit-role-button {
  font-size: 15px;
  padding-left: 5px;
}

.text-lowercase-role {
  text-transform: lowercase;
}
.text-lowercase-role::first-letter {
  text-transform: uppercase;
}
