.mc-apply-button-wrapper {
  position: absolute;
  top: -22px;
  right: 0;
  width: -webkit-fill-available;

  .dropdown-toggle::after {
    display: none;
  }

  /*Apply button*/
  .mc-apply-button {
    position: absolute;
    width: 20px;
    height: 15px;
    top: 5px;
    right: 5px;
    border-radius: 3px;
    border: 1px solid $mc-gray-border-color;
    background-color: $white;
    opacity: 0.8;

    &:focus {
      outline-style: none;
    }

    .mc-more-options {
      position: absolute;
      top: -10px;
      right: 4px;
      height: 10px;
    }

    &:hover {
      opacity: 1;
      animation: 0.3s;
    }
  }

  .mc-options-dropdown {
    border: 1px solid $mc-gray-border-color;
    background-color: $white;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    width: fit-content;
    height: auto;
    z-index: 500;
    position: absolute;
    right: -27px;
    top: 22px;
    font-size: 0.8rem;

    .mc-option-dropdown-item {
      &:hover {
        cursor: pointer;
        background-color: $mc-gray-background;
        opacity: .8;
      }
    }
  }
}

