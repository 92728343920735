.mc-add-product-to-invoice-wrapper {
  display: block;

  .mc-add-product-input-wrapper {
    width: auto;
    display: inline-flex;
  }

  .mc-add-product-quantity-input-field {
    max-width: 70px;
    text-align: center;
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .mc-decrement-item {
    background-color: $error_background_color;
    color: $error_color;

    &:hover {
      background-color: $error_color;
      color: $white;
      animation: ease-in-out;
    }
  }

  .mc-increment-item {
    background-color: $success_background_color;
    color: $success_color;

    &:hover {
      background-color: $success_color;
      color: $white;
      animation: ease-in-out;
    }
  }

  .mc-button-no-selected-products {
    background: $gray-500;
    color: $white;
    opacity: 0.65;

    &:hover {
      background-color: $success_color;
      color: $white;
      animation: ease-in-out;
    }
  }
}
