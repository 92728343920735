/*Table wrapper*/
.mc-table-wrapper {

 // overflow: hidden;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;

  /*Horizontal scrolling*/
  &.mc-table-horizontal-scroll {
    overflow-x: auto;
  }

  .vertical-align-middle {
    vertical-align: middle;
  }

  .mc-table-total-numbers-wrapper {
    width: fit-content;
    font-size: 0.8rem;
    font-weight: 500;
    padding-bottom: 5px;
    color: $mc-header-color;
  }

  .mc-table {
    margin: 0;
    margin-top: -1px;
    border-radius: $top-border-radius;
    -webkit-box-shadow: $general-shadow;
    -moz-box-shadow: $general-shadow;
    box-shadow: $general-shadow;
    background: $filter-bg;

    tr{
      &:last-child{
        td{
          &:first-child{
            border-bottom-left-radius: $border-radius-small;
          }
          &:last-child{
            border-bottom-right-radius: $border-radius-small;
          }
        }
      }
    }

    /*Table header*/
    .mc-table-head {
      color: black;

      tr{
        th{
          border-top:none;
          letter-spacing: 0.3px;
          border-top-left-radius: 4px;
          font-weight: 700;
          font-size: $middle-font;
          color: $cc-subtitle-color;
          &:first-child{
            border-top-left-radius: $border-radius-small
          }
          &:last-child{
            border-top-right-radius: $border-radius-small
          }
        }
      }

      /*Checkbox all*/
      .custom-control {
        display: inline-block;
        height: 12px;
        margin-top: -20px;
        margin-left: -24px;
        padding: 0;

        &.fill-checkbox {
          --color: #35B1F1;

          .fill-control-input {
            display: none;

            &:checked ~ .fill-control-indicator {
              background-color: #0c63bf;
              border-color: #0c63bf;
              background-size: 80%;
            }
          }

          .fill-control-indicator {
            position: absolute;
            top: 18px;
            left: 0;
            width: $medium-font;
            height: $medium-font;
            background-color: $mc-white-color;
            border: 2px solid black;
            background-size: 0;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
          }

          .fill-control-description {
            font-size: 14px;
          }
        }
      }

      /*Arrow wrapper*/
      .mc-arrow-wrapper {
        display: flex;
        flex-direction: column;
        font-size: $smallest-font;
        position: relative;
        margin-left: $margin-small;

        .mc-arrow-up-icon, .mc-arrow-down-icon {
          display: block;
          cursor: pointer;
        }
      }
    }
  }
    /*Table body*/
    .mc-table-body {

      td {
        vertical-align: middle;
        text-align: left;
        font-weight: 500;
        font-size: $small-font;
        line-height: $middle-font;
        color: $cc-subtitle-color;

      }

      tr {
        height: 50px;

        &:hover {
          background-color: $cc-table-hover-color !important;
        }

        .link-cell {
          display: flex;
          width: max-content;
        }

        .tr-icon {
          text-align: center;
          font-size: $small-font;
          padding: $padding-small;
          height: 24px;
          width: 24px;
          margin-right: $margin-small;
          color: $mc-white-color;
          background-color: $mc-blue-text-color;
          border-radius: $border-radius-small;
        }
      }

      /*Text area*/
      .mc-table-textarea {
        display: block;
        position: relative;
        min-width: 300px;

        /*Apply button*/
        .mc-table-textarea-apply-button {
          background: $white;
          position: absolute;
          width: 20px;
          height: 15px;
          top: 3px;
          right: 5px;
          border-radius: 3px;
          opacity: 1;
          border: 1px solid $mc-gray-border-color;

          &:focus {
            outline-style: none;
          }

          .mc-table-more-options {
            position: absolute;
            top: -11px;
            right: 4px;
            height: 10px;
            color: $black;
          }

          //&:hover {
          //  opacity: 1;
          //  animation: 0.3s;
          //}
        }

        .dropdown-toggle::after {
          display: none;
        }

        .mc-table-options-dropdown {
          border: 1px solid $mc-gray-border-color;
          background-color: $white;
          -webkit-border-radius: 5px;
          -moz-border-radius: 5px;
          border-radius: 5px;
          width: fit-content;
          height: auto;
          z-index: 500;
          position: absolute;
          right: -27px;
          top: 20px;
          font-size: 0.8rem;

          .mc-table-dropdown-item {
            &:hover {
              cursor: pointer;
              background-color: $mc-gray-background;
              opacity: .6;
            }
          }
        }

        textarea {
          min-height: 35px;
        }
      }

      /*Selected row*/
      .mc-selected-row {
        background: $table-selected-row;
      }

      .mc-table-category-name {
        font-style: italic;
      }

      /*table input field - invoice*/
      .mc-table-input-field {
        width: 110px;
      }

      /*Cursor pointer for table*/
      .mc-table-cursor-pointer {
        cursor: pointer;

        &:hover {
          color: $blue-filter-color;
          >* {
            color: $blue-filter-color;
          }
        }
        }
      }

      /*Edit row icon*/
      .mc-table-edit-row {
        color: $submit-button-color;
        cursor: pointer;
      }

      /*Table image*/
      .mc-table-image {
        width: 40px;
        border-radius: 50%;
      }

      /*Checkbox td*/
      .mc-checkbox-td {
        vertical-align: middle;
        width: 50px;

        .custom-control {
          display: inline-block;
          height: 12px;
          margin-top: -20px;
          margin-left: -24px;
          padding: 0;

          &.fill-checkbox {
            --color: #35B1F1;

            .fill-control-input {
              display: none;

              &:checked ~ .fill-control-indicator {
                background-color: var(--color);
                border-color: var(--color);
                background-size: 80%;
              }
            }

            .fill-control-indicator {
              position: absolute;
              top: 18px;
              left: 0;
              width: $medium-font;
              height: $medium-font;
              background-color: $mc-white-color;
              border: 2px solid black;
              background-size: 0;
              background-position: center;
              background-repeat: no-repeat;
              background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
            }

            .fill-control-description {
              font-size: 14px;
            }
          }
        }
      }

      td {
        padding: 8px 12px;
        text-align: center;
      }

      .mc-readonly {
        background: gainsboro;
        cursor: not-allowed;
      }
    }

    .mc-table-summary {
      background: $table-selected-row;

      .mc-tr-black {
        background-color: rgba(0,0,0,0.5);
        color: $white;
      }
      .mc-tr-silver {
        background-color: lighten(#9ca0a3, 20%);
        color: #000;
      }
    }

  /*MC table footer*/
  .mc-table-footer {
    height: 50px;
    color: $cc-subtitle-color;
    background: $white-color;
    border-radius: 0px 0px 4px 4px;
    margin: 0;
    border-top: 1px solid rgba(1, 21, 34, 0.2);
    align-items: center;
    justify-content: end;
    padding-right: 10px;

    .mat-mdc-paginator {
      color: #3B457C;
      font-size: 14px;
      border-top: none !important;
    }

    .number-per-page-wrap {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      color: $cc-subtitle-color;
      font-weight: 500;
      font-size: 14px;

      /*Number per page*/
      .mc-number-per-page {
        width: 100px;
        height: 40px;
        border: 1px solid rgba(59, 69, 124, 0.38);
        border-radius: 4px;
        flex: none;
        font-weight: 500;
        font-size: $middle-font;
        letter-spacing: 0.5px;
        color: rgba(59, 69, 124, 0.6);
      }
    }

    /*Go to page*/
    .mc-go-to-page {
      width: 50px;
    }

    /*Pagination page error message*/
    .mc-page-error-message {
      position: absolute;
      padding: 5px
    }
  }


.mc-permissions-by-role-modal {

  .modal-content {
    background: $personal-info-box;
    padding-bottom: 20px;

    .modal-title {
      margin: 0 auto;
    }

    .mc-label-text {
      font-size: 14px;
      line-height: 25px;
    }
  }
}

/*Table settings button*/
.mc-table-settings-button {
  width: auto;
  padding: 3px 6px;
  border-radius: 5px;

  &:hover {
    cursor: pointer;
    opacity: .8;
  }

  .mc-table-setting-icon {
    color:  #35B1F1;
    font-size: 17px;
  }
}

/*Import Consumers By CSV TAble*/
.mc-consumers-import-table {
/*  overflow-y: scroll;
  height: 300px;*/
  td {
    vertical-align: middle;
  }
}
.mc-my-billing-statement-items-table {
  td:nth-of-type(8),
  td:nth-of-type(9),
  td:nth-of-type(10) {
    text-align: right !important;
  }
}
.mc-transactions-table {
  .icon-tooltip {
    position: relative;
    display: inline-block;
    z-index: 1;
  }
  .icon-tooltip .icon-tooltip-text {
    visibility: hidden;
    width: 150px;
    background-color: #FFFFFF;
    color: #000000;
    text-align: center;
    border-radius: 4px;
    padding: 8px 4px;
    position: absolute;
    z-index: 1;
    top: 150%;
    left: 70%;
    margin-left: -60px;
    font-size: 12px;
    box-shadow: 0 0 12px rgba(0,0,0,0.2);
  }
  .icon-tooltip .icon-tooltip-hidden {
    visibility: hidden;
    width: 150px;
    background-color: #FFFFFF;
    color: #000000;
    text-align: center;
    border-radius: 6px;
    padding: 3px 3px;
    position: absolute;
    z-index: 1;
    top: 150%;
    left: 70%;
    margin-left: -60px;
    font-size: 12px;
  }
  .icon-tooltip .icon-tooltip-text:after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 35%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent white transparent;

  }
  .icon-tooltip:hover .icon-tooltip-text {
    visibility: visible;
  }
  .icon-tooltip:hover .icon-tooltip-hidden {
    visibility: hidden;
  }
  .mc-text-capitalize {
      text-transform: capitalize;
    }
  td:last-child {
    width: 15%;
  }
  img {
    padding-right: 5px;
    &:first-child {
      width: auto;
      height: 30px !important;
    }
    width: auto;
    height:24px;
  }
}

.mc-pricelist-table {
  td:nth-of-type(4) {
    width: 30%;
  }
  td:nth-of-type(5) {
    width: 20%;
  }
}

.mc-table-head-item {
  font-weight: bold;
  text-align: left;

  .table-head-title {
    display: flex;
    align-items: center;
  }
}

/*Table column width*/
.table-col-width-50 {
  width: 50px;
}


/*Background grey color*/
.import-consumers-grey-background {
  background-color: #dee2e6;
}
/*Table input text fields*/
.import-consumers-input-text-height {
  height: 30px;
}

@media only screen and (max-width: 1450px) {
  .mc-transactions-table {
    td:last-child {
      width: 25%;
    }
  }
}

@media only screen and (max-width: 1310px) {
  .mc-transactions-table {
    td:last-child {
      width: 29%;
    }
  }
}

@media only screen and (max-width: 1220px) {
  .mc-transactions-table {
    td:last-child {
      width: 35%;
    }
  }
}
