.mc-login-area {
  background-size: cover;
  box-shadow: inset 0 0 0 1000px rgba(58, 120, 151, .01);

  .mc-login-card {
    .mc-login-header {
      img {
        max-width: 200px;
        padding: 60px 0 80px;
        box-sizing: content-box;
      }
    }

    .mc-login-card-body {
      padding: 0 60px 60px;

      .mc-notify-message-wrapper {
        top: 300px;
        width: 100%;
      }

      form {
        padding-top: 70px;
      }
    }
  }
}

  .mc-login-password-fields {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 0;
    .mc-login-input {
      margin-bottom: 40px;
      label {
        display: none;
      }
    }
    input {
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
      font-size: 14px;
      padding: 20px;
    }
  }
  .mc-login-btn-area {
    padding: 10px 0 0 0;
    width: 100%;
    button {
      width: 100%;
      border-radius: 20px;
      margin-bottom: 30px;
    }
  }
  .mc-login-loginAlternatives {
    align-items: center;
    color: $login-blue-color;
    .mc-divider a {
      float: right;
    }
    a {
      color: $login-blue-color;
      text-decoration: none;
    }
    .mc-keep-me-logged {
      & div,
      & .custom-control.fill-checkbox {
        margin-bottom: 0;
      }
      .custom-control.fill-checkbox .fill-control-indicator {
        border-radius: 0;
        border-color: $login-blue-color;
      }
      .custom-control.fill-checkbox .fill-control-description {
        font-size: 1rem;
        text-transform: none;
      }
    }
  }

// Responsive
@media only screen and (max-width: $middle-device) {
  .mc-login-area {
    .mc-login-card .mc-login-card-body {
      padding: 0 40px 40px;
    }
  }
}

@media only screen and (max-width: $small-device) {
  .mc-login-area {
    .mc-login-header {
      img {
        height: 60px;
      }
    }
    .mc-login-card .mc-login-card-body {
      padding: 0 30px 30px;
    }
  }
}
@media only screen and (max-width: $extra-small-device) {
  .mc-login-area {
    .mc-login-header {
      img {
        height: 50px;
      }
    }
    .mc-login-loginAlternatives {
      .mc-divider {
        border-right: none;
        min-width: 100%;
        a {
          float: left;
        }
      }
      .mc-keep-me-logged {
        margin-top: 15px;
        min-width: 100%;
      }
    }
  }
}
