.mc-select-entity-modal {
  z-index: 1200;
  .modal-dialog{
    min-width: 1200px;

    .modal-content {
      min-height: 800px;
    }

    .modal-body {
      background-color: $mc-aliceblue;
    }
  }
}
.mc-select-entity-wrapper {
  //background-size: cover;
  //box-shadow: inset 0 0 0 1000px rgba(58, 120, 151, .65);
  //height: inherit;

  // Search entity wrapper
  .mc-search-entity-wrapper {
    position: relative;

    // Search entity fa fa icon
    .mc-search-entity-icon {
      position: absolute;
      top: 10px;
      right: 30px;
      color: $gray-500;
    }
  }

  // Filter by latter section
  .mc-letter-filter {

    // Filter items
    .mc-latter-item {
      padding: 5px;
      font-size: 20px;
      cursor: pointer;
    }

    // Selected item
    .selected {
      color: $blue;
      background-color: $login-header-color;
    }
  }

  // Entity tree wrapper
  .mc-entities-tree-wrapper {
    //box-shadow: inset 0 0 0 1000px rgba(218, 236, 248, 0.65);

    // Entity list wrapper
    .mc-entity-list-wrapper {
      display: inline-block;
      //height: 500px;
    }

    .mc-root-entity-name {
      font-size: 22px;
      font-weight: bold;
      padding-bottom: 5px;
    }

    .mc-selected-entity {
      width: fit-content;
      background-color: $login-blue-color;
      color: $white;
    }

    .mc-arrow-icon {
      padding-right: 5px;
    }

    .mc-entity-name {
      display: inline-block;
      padding: 0px 10px 0px 10px;
    }
  }

  .mc-proceed-button {
    position: relative;
    float: right;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    padding: 5px 30px;
    color: $login-blue-color;
    background-color: $white;
  }

  // Mobile design
  @media only screen and (max-width: $medium-device) {
  }
}
