// Create entity modal
.mc-create-new-entity-modal {
  .modal-dialog {
    max-width: 1350px;
  }

  // Color classes
  .mc-blue-text {
    color: $login-blue-color;
  }

  .mc-active {
    color: $login-blue-color;
    border-bottom: 3px solid $login-blue-color;
  }

  .mc-active-background {
    background: $login-blue-color;
  }

  .mc-semi-active {
    color: lighten($login-blue-color,15%);
  }

  .mc-semi-active-background {
    background-color: lighten($login-blue-color,15%);
  }

  .mc-error-field {
    border-color: $error_color;
    &:focus {
      border-color: $error_color;
      box-shadow: 0 0 0 0.2rem rgba(236, 35, 13,.25)
    }
  }

  .enable-icon {
    margin-left: 5px;
    border-radius: 10px;
    border: 1px solid #35B1F1;
    outline: navajowhite;
    padding: 0 4px !important;
    font-size: 13px;
    background: #35B1F1;
    color: $mc-white-background;
  }

  .disable-icon {
    margin-left: 5px;
    border-radius: 10px;
    border: 1px solid gray;
    outline: navajowhite;
    padding: 0 4px !important;
    font-size: 13px;
    background: lightgray;
    color: gray;
  }

  .info-icon {
    border-radius: 10px;
    border: 1px solid #bad8e8;
    outline: navajowhite;
    padding: 0 6px !important;
    font-size: 13px;
    font-weight: bold;
    background: #bad8e8;
    color: #35B1F1;
    cursor: pointer;
  }

  .description-attribute .tooltip-inner {
    background-color: $mc-info-btn-color;
    font-size: 125%;
  }
  .description-attribute .arrow::before {
    border-left-color: $mc-info-btn-color;
  }

  .fa-share-square {
    margin-top: -2px;
  }

  .fa-star {
    margin-top: -2px;
    margin-left: -9px;
  }

  .mc-pen-icon {
    font-size: 14px;
  }

  .border-bottom-row {
    border-bottom: 1px solid $gray-500;
  }

  .summary-entity-image {
    width: 100%;
    max-width: 120px;
    max-height: 120px;
    border: 1px solid lightgrey;
    display: block;
  }

  // custom checkbox
  .custom-control {
    display: inline-block;

    &.fill-checkbox {
      --color: #35B1F1;

      .fill-control-input {
        display: none;

        &:checked ~ .fill-control-indicator {
          background-color: var(--color);
          border-color: var(--color);
          background-size: 80%;
        }
      }

      .fill-control-indicator {
        position: absolute;
        top: 6px;
        left: 0;
        width: 14px;
        height: 14px;
        border: 1px solid #aaa;
        background-size: 0;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
      }

      .fill-control-description {
        font-size: 14px;
        cursor: pointer;
      }
    }
  }

  .modal-content {
    border-radius: 0;
    padding: 10px 50px;
    position: relative;

    // modal header
    .mc-create-entity-modal-header {
      padding: 16px 0 0;
    }
    .mc-entity-close-btn.close {
      font-size: 60px;
      font-weight: 100;
      outline: none;
      padding: 0;
      position: relative;
      top: -13px;
      left: 21px;
      color: $mc-gray-border-color;
      &:focus {
        outline: none;
      }
    }

    // modal body
    .modal-body {
      background: $mc-gray-background;
      border-top: 3px solid $mc-gray-line;
      margin-bottom: 20px;
      padding-left: 0;
      padding-right: 0;

      section.container {
        max-width: 100%;
        padding: 0 25px;
      }

      ul.nav.nav-tabs {
        justify-content: center !important;
        border-bottom: none;
      }

      li.nav-item {
        padding: 0 10px;

        & > a.nav-link {
          background: transparent;
          border: none;
          color: $login-gray-color;
          display: flex;
          font-size: 20px;
          justify-content: space-between;
          align-items: center;
          padding: 5px;

          &.active {
            border-bottom: 3px solid $login-blue-color;
            color: $login-blue-color;

            .mc-notification-badge-gray {
              background: $login-blue-color;
            }
          }
        }
      }

      .custom-control {
        &.fill-checkbox {
          --color: #35B1F1;

          .fill-control-input {
            display: none;

            &:checked ~ .fill-control-indicator {
              background-color: var(--color);
              border-color: var(--color);
              background-size: 80%;
            }
          }

          .fill-control-indicator {
            position: absolute;
            top: 4px;
            left: 0;
            width: 14px;
            height: 14px;
            border: 1px solid #aaa;
            background-size: 0;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
          }

          .fill-control-description {
            font-size: 14px;
          }
        }
      }

      .mc-next-save-btn {
        background: $mc-blue-text-color;
        border: none;
        padding-left: 30px;
        padding-right: 30px;
        border-radius: 20px;

        &:focus {
          box-shadow: none;
        }

        &:hover {
          background: darken($mc-blue-text-color, 15%);
        }
      }
    }
  }

  .mc-notify-wrapper-summary-entity {
    position: relative;
    top: -40px;
    margin-bottom: 30px;
  }

  .mc-prev-btn {
    //background: #35b1ec;
    border: none;
    border-radius: 20px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .mc-right-border-summary {
    border-right: 1px solid $mc-gray-line;
  }

  .mc-entity-summary-title {
    color: $login-blue-color;

    h6 {
      display: inline-block;
      font-size: 18px;
    }
  }

  .set-font {
    font-size: 12px;
    font-weight: 500;
  }

  .right-basic-info-col {
    font-size: 15px;
    font-weight: 400;
  }

  .left-pass-col {
    padding-right: 0;
  }

  .right-pass-col {
    font-size: 14px;
    font-weight: 400;
    margin-top: -1px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .mc-entity-summary-create-btn {
    position: absolute;
    bottom: 16px;
    right: 25px;
    z-index: 100;
  }

  .mc-entity-summary-createAdd-btn {
    position: absolute;
    bottom: 16px;
    right: -115px;
    z-index: 100;
  }

  .mc-entity-summary-create-btns {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .mc-next-save-btn:first-child {
      background-color: darken($login-blue-color, 10%);
    }
  }
  // Footer
  footer.step-navigation-bar {
    margin-bottom: 0 !important;
    margin-top: 30px !important;
  }
}



// Create new entity btn
.mc-create-entity-btn {
  color: $mc-blue-text-color !important;
  cursor: pointer;
  font-weight: 500;
  text-decoration: none !important;

  &:hover {
    color: darken($mc-blue-text-color, 10%) !important;
  }
}

// Basic-info
.mc-entity-basic-info {

  .entity-basic-info-form {
    padding: 3% 3% 0;

    .mc-notify-wrapper-basic-info {
      position: relative;
      top: -18px;
      margin-bottom: 2.3%;
    }


    .entity-image {
      width: 100%;
      max-width: 120px;
      max-height: 120px;
      border: 1px solid lightgrey;
      display: block;
    }

    .hidden-input {
      opacity: 0;
      z-index: 10;
      position: absolute;
      width: 93px;
      height: 19px;
    }

    .upload-img {
      color: $mc-blue-text-color;
      position: relative;
      top: -2px;
      font-size: 90%;
    }
  }
  label {
    margin-bottom: 1%;
    color: $login-label-color;
  }
  input, textarea {
    border: 1px solid $login-input-color;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    //padding: 10px;
    font-size: 14px;
  }
  textarea {
    resize: none;
  }
  .hide-margin {
    margin-bottom: 0;
  }
  .gray-text-info {
    font-size: 13px;
    color: $mc-gray-border-color;
  }
  .required-input {
    color: $mc-red-color-text;
  }
  .mc-confirm-btn {
    background-color: $mc-blue-text-color;
    color: $bg_color;
    padding: 4px 25px;
    float: right;
    border: none;
    outline: none;
    -webkit-border-radius: 50px 50px 50px;
    -moz-border-radius: 50px 50px 50px;
    border-radius: 50px 50px 50px;
  }
  .mc-disable-btn {
    padding: 4px 25px;
    float: right;
    border: none;
    outline: none;
    -webkit-border-radius: 50px 50px 50px;
    -moz-border-radius: 50px 50px 50px;
    border-radius: 50px 50px 50px;
    background-color: $login-gray-color !important;
    color: $bg_color;
  }
}

// Attributes
.mc-entity-attributes-area {

  .mc-entity-attributes-wrapper {
    padding: 0 3%;

    .mc-notify-wrapper-attributes {
      position: relative;
      top: -32px;
      margin-bottom: 1.3%;
    }

    .mc-attributes {

      // Local attributes table
      .mc-table-local-attributes-wrapper {
        overflow: auto;

        .mc-table-local-attributes {
          & * {
            padding: 6px;
          }
          .mc-table-header-local-attributes {
            border-bottom: 3px solid $gray-500;

            th {
              font-size: 13px;
              font-weight: 500;
            }
          }

          .delete-local-attribute {
            font-size: 16px;
            color: gray;
            font-weight: 100;
            cursor: pointer;
          }
        }
      }

      .inheritSelection {
        cursor: pointer;
      }

      .attributes-subtitle {
        font-size: 18px;
      }

      // Inherited attributes table
      .mc-table-inherited-attributes-wrapper {
        overflow: auto;

        .mc-table-inherited-attributes {
          & * {
            padding: 6px;
          }

          .mc-table-header-inherited-attributes {
            border-bottom: 3px solid $gray-500;

            th {
              font-size: 13px;
              font-weight: 500;
            }
          }

          .label-wrapper {
            padding: 0 14px 0 0 !important;

            .checkbox-attributes-position {
              top: 12px !important;
              left: 5px !important;
            }
          }
        }
      }
    }

    .mc-confirm-btn {
      background-color: $mc-blue-text-color;
      color: $bg_color;
      padding: 4px 25px;
      float: right;
      border: none;
      outline: none;
      -webkit-border-radius: 50px 50px 50px;
      -moz-border-radius: 50px 50px 50px;
      border-radius: 50px 50px 50px;
    }
    .mc-disable-btn {
      padding: 4px 25px;
      float: right;
      border: none;
      outline: none;
      -webkit-border-radius: 50px 50px 50px;
      -moz-border-radius: 50px 50px 50px;
      border-radius: 50px 50px 50px;
      background-color: $login-gray-color !important;
      color: $bg_color;
    }
  }
}

// Password-policy
.mc-entity-password-policy {

  .entity-password-policy-form {
    padding: 3% 3% 0;

    .mc-notify-wrapper-password-policy {
      position: relative;
      top: -25px;
      margin-bottom: 1.3%;
    }
    .mc-notify-wrapper-create-password-policy {
      position: relative;
      top: -33px !important;
      margin-bottom: 1.3%;
    }

    .parent-password-policy {
      .custom-control {
        &.fill-checkbox {
          --color: #35B1F1;

          .fill-control-input {
            display: none;

            &:checked ~ .fill-control-indicator {
              background-color: var(--color);
              border-color: var(--color);
              background-size: 80%;
            }
          }

          .fill-control-indicator {
            position: absolute;
            top: 5px;
            left: 0;
            width: 14px;
            height: 14px;
            border: 1px solid #aaa;
            background-size: 0;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
          }

          .fill-control-description {
            font-size: 14px;
          }
        }
      }
    }

    .invalid-number {
      padding-left: 3px;
      padding-right: 22px;
    }

    .restore-policy {
      color: $title-color;
      display: inline-block;
      float: right;
      margin-top: -25px;
      cursor: pointer;
      //display: flex;
      //justify-content: flex-end;
    }
    .parent-policy-wrapper {
      border: 3px solid $mc-gray-border-color;
      padding: 5%;
      position: relative;

      .parent-policy-text {
        background: $personal-info-box;
        color: $mc-gray-border-color;
        position: absolute;
        top: -14px;
        padding: 0 20px;
      }
    }

    .disable-restore-btn {
      color: #B7B7B9;
      pointer-events: none;
    }

    .disable-password-policy-wrapper {
      pointer-events: none;

      .disable-password-policy {
        background-color: #ced5e2;
        opacity: 0.4;
        pointer-events: none;
      }
    }
  }
  .center-label-wrap {
    display: flex;
    align-items: center;
  }
  label {
    margin-right: 10%;
    margin-bottom: 0;
    color: $login-label-color;
  }
  input {
    border: 1px solid $login-input-color;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    font-size: 14px;
    width: 40%;
    display: inline-block;
    margin-right: 2%;
  }
  .hide-margin {
    margin-bottom: 0;
  }
  .gray-text-info {
    font-size: 13px;
    color: $mc-gray-border-color;
  }
  .required-input {
    color: $mc-red-color-text;
  }
  .mc-confirm-btn {
    background-color: $mc-blue-text-color;
    color: $bg_color;
    padding: 4px 25px;
    float: right;
    border: none;
    outline: none;
    -webkit-border-radius: 50px 50px 50px;
    -moz-border-radius: 50px 50px 50px;
    border-radius: 50px 50px 50px;
  }
  .mc-disable-btn {
    padding: 4px 25px;
    float: right;
    border: none;
    outline: none;
    -webkit-border-radius: 50px 50px 50px;
    -moz-border-radius: 50px 50px 50px;
    border-radius: 50px 50px 50px;
    background-color: $login-gray-color !important;
    color: $bg_color;
  }
}

// Local attributes modal
.mc-entity-local-attributes-modal {

  .modal-dialog {
    max-width: 600px;

    .modal-body {
      background-color: #EAEBF0;
      padding: 25px 35px 29px;

      .required-input {
        color: $error_color;
      }

      .mc-local-attributes-title.modal-title {
        border-bottom: 1px solid $gray-500;
      }

      .mc-entity-attributes-form {

        .mc-create-entity-notify-wrapper {
          position: relative;
          top: -38px;
          margin-bottom: 47px;
        }

        input, textarea {
          border-radius: 0;
        }

        .inheritable-radio-btns-group {
          margin-top: 31px;
        }
        .inheritable-wrapper {
          margin-top: 6px;
        }

        .required-wrapper {
          margin-top: 6px;
        }

        .disable-restore-btn {
          color: #B7B7B9;
          pointer-events: none;
        }

        .disable-checkbox {
          background-color: #e9ecef !important;
          pointer-events: none;
        }

        .mc-close-local-attribute-btn {
          float: left;
          font-size: 15px;
          font-weight: 500;
          color: black !important;
          cursor: pointer;
          outline: none;
        }

        .mc-save-local-attribute-btn {
          float: right;
          font-size: 15px;
          background-color: $mc-blue-text-color;
          color: $bg_color;
          padding: 7px 24px;
          border: none;
          outline: none;
          -webkit-border-radius: 50px 50px 50px;
          -moz-border-radius: 50px 50px 50px;
          border-radius: 50px 50px 50px;
        }

        .mc-disable-btn {
          padding: 4px 25px;
          float: right;
          border: none;
          outline: none;
          -webkit-border-radius: 50px 50px 50px;
          -moz-border-radius: 50px 50px 50px;
          border-radius: 50px 50px 50px;
          background-color: $login-gray-color !important;
          color: $bg_color;
        }
      }

      // custom checkbox
      .custom-control {
        display: inline-block;

        &.fill-checkbox {
          --color: #35B1F1;

          .fill-control-input {
            display: none;

            &:checked ~ .fill-control-indicator {
              background-color: var(--color);
              border-color: var(--color);
              background-size: 80%;
            }
          }

          .fill-control-indicator {
            position: absolute;
            top: 5px;
            left: 0;
            width: 14px;
            height: 14px;
            border: 1px solid #aaa;
            background-color: $mc-white-background;
            background-size: 0;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
          }

          .fill-control-description {
            font-size: 14px;
            cursor: pointer;
          }
        }
      }
    }
  }
}

// custom checkbox
.custom-control {
  display: inline-block;

  &.fill-checkbox {
    --color: #35B1F1;

    .fill-control-input {
      display: none;

      &:checked ~ .fill-control-indicator {
        background-color: var(--color);
        border-color: var(--color);
        background-size: 80%;
      }
    }

    .fill-control-indicator {
      position: absolute;
      top: 6px;
      left: 0;
      width: 14px;
      height: 14px;
      border: 1px solid #aaa;
      background-size: 0;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
    }

    .fill-control-description {
      font-size: 14px;
      cursor: pointer;
    }
  }
}

.gray-background {
  background: $mc-gray-background;
}

.mc-entity-label {
  display: block;
  font-size: 15px;
  font-weight: 500;
}

ul.dashed {
  list-style-type: none;
}
ul.dashed > li:before {
  content: "-";
}
.mc-factoring ul {
  padding: 0;
  li{
    font-size: 16px !important;
    cursor: unset !important;
  }
}

.mc-type-of-requests {
  .modal-dialog {
    max-width: 400px;

    .modal-content {
      border-radius: 0;
      padding: 20px 30px 30px !important;
      .modal-header {
        border-bottom: none;
        padding-top:  5px;
        .mc-product-close-btn span {
          position: absolute;
          right: 11px;
          top: 0;
        }
        .close {
          font-size: 60px;
          font-weight: 100;
          outline: none;
          padding: 0 10px;
          color: $mc-gray-border-color;
        }
      }
      .modal-body {
        padding: 0 !important;
        #modal-basic-title {
          margin-bottom: 0;
          border-bottom: 3px solid $mc-gray-border-color;

        }
        .mc-notify-wrapper {
          position: relative;
          top: -15px;
          margin-bottom: 19px;
        }
        .category-form {
          background: $personal-info-box;
          padding: 5%;
          border-top: 3px solid $mc-gray-border-color;

          .align-label {
            display: flex;
            align-items: center;
          }
          .euro-sign {
            font-size: 14px;
            margin-left: 28px;
          }
        }
        label {
          margin-bottom: 1%;
          display: block;
          font-size: 14px;
          font-weight: 500;
        }
        input, textarea {
          border: 1px solid $login-input-color;
          -webkit-border-radius: 0;
          -moz-border-radius: 0;
          border-radius: 0;
          font-size: 14px;
        }
        textarea {
          resize: none;
        }
        .hide-margin {
          margin-bottom: 0;
        }
        .gray-text-info {
          font-size: 13px;
          color: $mc-gray-border-color;
        }
        .required-input {
          color: $mc-red-color-text;
        }
        .mc-confirm-btn {
          background-color: $mc-blue-text-color;
          color: $bg_color;
          padding: 4px 25px;
          float: right;
          border: none;
          outline: none;
          -webkit-border-radius: 50px 50px 50px;
          -moz-border-radius: 50px 50px 50px;
          border-radius: 50px 50px 50px;
        }
        .mc-disable-btn {
          padding: 4px 25px;
          float: right;
          border: none;
          outline: none;
          -webkit-border-radius: 50px 50px 50px;
          -moz-border-radius: 50px 50px 50px;
          border-radius: 50px 50px 50px;
          background-color: $login-gray-color !important;
          color: $bg_color;
        }
      }
    }
  }
}
.mc-edit-entity-basic-info-modal {
  .mc-factoring-modal-title {
    border-bottom: 3px solid $mc-gray-border-color;
  }
}

.mc-entity-factoring {
  .mc-factoring-textbox {
    .error-msg {
      font-size: 75%;
      margin-right: 50px;
    }
    height: 50%;
    input {
      width: 70%;
    }
    label {
      width: 60%;
      padding-right: 30px !important;
    }
  }
  .mcc-fi-contractStartDate {
    .error-msg {
      font-size: 75%;
      margin-right: 227px !important;
    }
  }
  .mcc-fi-contractStartDate,
  .mcc-fi-contractEndDate {
    .error-msg {
      font-size: 75%;
      margin-right: 172px;
    }
    padding-left: 10px;
    input {
      width: 40%;
    }
    label {
      width: 80%;
      padding-right: 30px !important;
      font-size: 12px !important;
      color: $mc-gray-border-color !important;
      font-weight: 600 !important;
    }
  }
  form {
    padding: 3% 3%;

    input[type=text] {
      color: black;
      padding-left: 2px;
    }

    label {
      font-size: 12px !important;
      color: $mc-gray-border-color !important;
      font-weight: 600 !important;
      // padding-right: 30px !important;

      i {
        position: absolute;
        right: 0;
      }
    }
  }
  .hide {
    display: none;
  }
  .show {
    display: block;
  }
  .btn-add {
    background: none !important;
    border: none;
    color: $mc-gray-border-color;
    cursor: pointer;
  }
}
.mc-hidden {
  display: none;
}
.mc-factoring-table {
  position: absolute;
  left: 0;
  right: 0;
  margin-right: -45px;
  button {
    font-size: 14px !important;
  }
}
.mc-factoring-notify-message {
  .mc-notify-message-wrapper  {
    margin-top: 12px;
    z-index: 1;
  }
}


