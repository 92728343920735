.mc-header {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  max-width: inherit;
  z-index: 999;
  padding-top: 10px;

  /*TODO: discuss about logo position width and height! */
  .mc-header-logo {
    width: auto;
    max-width: 200px;
    height: auto;
    margin-top: 3px;
    outline: none;
  }

  /*Logo wrapper*/
  div.mc-header-logo-wrapper {
    flex: 1;
    height: auto;
    width: fit-content;
    background: $filter-bg;
    box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.2);
    border-radius: 0px 4px 4px 0px;
    padding: 5px 15px;
    display: flex;
    align-items: center;
    margin-left: -15px;
  }

  .mc-header-collapse-icon {
    min-width: 60px;
    justify-content: center;
    height: 40px;
    display: flex;
    border-right: 1px solid $cc-border-color;
    cursor: pointer;
    align-items: center;
  }

  .logo-icon {
    cursor: pointer;
  }

  .mc-header-content {
    display: flex;
    justify-content: flex-end;
    flex: 2;
    padding: 0;
  }

  .mc-header-user-section {
    display: flex;
    gap: 10px;
    padding-left: 15px;
    img {
      width: 30px;
      height: 30px;
    }
    &_name {
      font-weight: 500;
      font-size: $middle-font;
      color: $cc-subtitle-color;
    }
  }

  .mc-row-no-margin {
    display: flex;
    justify-content: space-between;
    line-height: 32px !important;
  }

  .mc-arrow {
    margin-left: 0;
    padding-top: 4px;
    width: 20px;
    height: 20px;
  }

  .center-content {
    display: flex;
    align-items: center;
    height: 35px;

    .mc-header-path {
      padding-left: 10px;
      display: flex;
      align-items: center;
      border-right: 1px solid $cc-border-color;

      .mc-entity-toggle-btn {
        background: transparent;
        border: none;
        line-height: normal;

        &:focus {
          outline: none;
        }
      }

      span {
        font-weight: 600;
        font-size: $middle-font;
        color: $cc-subtitle-color;
        margin-right: 5px;
      }
    }
  }

  .right-content {
    display: flex;
    justify-content: flex-end;

    .mc-header-actions {
      color: $mc-gray-text-1;
      list-style: none;
      display: flex;
      align-items: center;
      margin-bottom: 0;
      background: $filter-bg;
      box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.2);
      border-radius: 4px;
      height: 50px;
      padding: 0 !important;

      .mc-header-right-action {
        position: relative;
        padding: 0 16px;
        line-height: 2;
        border-right: 1px solid $cc-border-color;
        height: 35px;
        transition: 0.2s ease-in-out;
        display: flex;
        align-items: center;

        &:last-child {
          border: none;
        }

        .software-version {
          margin-left: 5px;
        }
      }

      li.mc-header-notifications {
        cursor: pointer;

        .far {
          font-size: 22px;
          padding-right: 5px;
        }

        .mc-notification-badge-orange {
          background: $mc-notification-color;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 50%;
          color: $white;
          font-size: 8px;
          line-height: 1.1;
          padding: 4px 5px 2px;
          position: absolute;
          top: 12px;
          right: 12px;
        }
      }

      .help-link {
        display: flex;
        justify-content: center;
        align-items: center;

        i {
          color: $cc-subtitle-color;
          font-size: 20px;
        }
      }
    }

    .mc-header-lang > div {
      position: static;
      width: 100%;
    }

    .mc-dropdown-toggle {
      cursor: pointer;
      padding: 1px 2.5px;
      position: static;
      -webkit-appearance: none;
      outline: 0;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: $cc-subtitle-color;
      background: $filter-bg;

      &::after {
        display: none;
      }

      svg {
        margin-right: -5px;
        path {
          fill: $cc-subtitle-color;
        }
      }
    }

    #languageDropdown {
      background: $filter-bg;
      align-items: center;
      display: flex;
    }

    .mc-dropdown-menu {
      background: $filter-bg;
      border-radius: 0;
      min-width: auto;
      padding: 0;
      transform: translate(0, 0) !important;
      top: 100% !important;

      a {
        color: $white;
        transition: all 0.2s ease-in-out;
        cursor: pointer;

        &:hover {
          color: $mc-header-color;
        }

        &:active {
          background: $mc-white-background;
        }
      }
    }

    .mc-dropdown-language {
      position: absolute;
      border: 1px solid rgba(0, 0, 0, 0.15);
      top: 100%;
      left: 0;
      z-index: 1000;
      margin: 0.125rem 0;
      font-size: 1rem;
      text-align: left;

      .language-link {
        padding: 5px 10px;
        font-size: 15px;
        display: flex;
        justify-content: space-between;
        color: $mc-gray-text-1;
        transition: all 0.2s ease-in-out;
        cursor: pointer;
        margin-right: 50px;

        &:active, &:hover {
          color: $mc-header-color;
          background: $cc-table-hover-color;
        }

        &:hover .mc-dropdown-toggle-small {
          background: $mc-white-background;
        }

        .mc-dropdown-toggle-small {
          background: $mc-gray-text-3;
          border: none;
          border-radius: 50%;
          color: $mc-header-color;
          cursor: pointer;
          font-size: 11px;
          font-weight: 500;
          width: 20px;
          height: 20px;
          line-height: 20px;
          padding: 1px 2.3px;
          position: static;
          -webkit-appearance: none;
          outline: 0;
          margin-top: 2px;
        }

        .toggle-it {
          padding: 1px 5px !important;
        }
      }
    }

    .mc-header-profile {
      padding: 0 !important;
      max-width: 230px;
      width: 100%;
      display: flex;
      justify-content: space-between;

      & > div {
        display: flex;
        align-items: center;
        position: static;
        width: 100%;
      }

      img {
        width: 20px;
        height: 20px;
        margin: 0 12px;
      }

      .mc-header-profile-name {
        padding: 10px 30px 10px 0;
        position: relative;
        cursor: pointer;
        line-height: 1;
        white-space: normal;
        word-break: break-word;
        width: 100%;
        font-weight: 500;
        font-size: $middle-font;
        color: $cc-subtitle-color;

        &::after {
          position: absolute;
          top: 50%;
          right: 12px;
          transform: translateY(-50%);
        }

        &:hover {
          color: $mc-gray-text-2;
        }
      }

      .mc-dropdown-profile {
        width: fit-content;
        position: absolute;
        border: 1px solid rgba(0, 0, 0, 0.15);
        left: -50px !important;
        z-index: 1000;
        margin: 0.125rem 0;
        font-size: 1rem;
        text-align: left;

        .profile-link {
          color: $mc-gray-text-1;
          font-size: 15px;

          &:active, &:hover {
            color: $mc-white-background;
            background: none !important;
          }
        }
      }
    }

    .mc-header-logout {
      padding-right: 20px;

      a {
        cursor: pointer;
        color: $mc-gray-text-1;
        display: flex;
        align-items: center;

        &:hover {
          color: $mc-gray-text-2;
        }
      }

      .mc-header-logout-icon {
        width: 20px;
        height: 20px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
  }
}
