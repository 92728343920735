/* You can add global styles to this file, and also import other style files */

@import "./mcc-basic-style.scss";

@import "../projects/miticon-ui/mc-core-ui/src/lib/assets/scss/styles.scss";

@import "@angular/material/prebuilt-themes/indigo-pink.css";

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
