.mc-auth-locked-wrapper {
  height: inherit;
  background-size: cover;
  box-shadow: inset 0 0 0 1000px rgba(58, 120, 151, .01);

  .mc-card {
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;

    .mc-card-header {
      padding: 50px 30px 0;

      .mc-go-back {
        position: absolute;
        top: 5px;
        left: 8px;
        font-size: 14px;
        color: $login-blue-color;
        cursor: pointer;
      }
    }

    .mc-card-body {
      position: relative;
    }
  }

  //Mobile design
  @media only screen and (max-width: $small-device) {
  }
}

