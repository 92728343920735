.mc-forgot-password{
  background-size: cover;
  box-shadow: inset 0 0 0 1000px rgba(58,120,151,.01);

  // MC card
  .mc-card {
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;

    // MC card header
    .mc-card-header {
      padding: 40px 20px 0
    }

    .mc-card-body {
      position: relative;
    }

    .mc-submit-button {
      background-color: $login-blue-color;
    }

    .mc-error {
      height: fit-content;
      margin-bottom: 50px;
    }
  }

  //Mobile design
  @media only screen and (max-width: $medium-device) {
    .mc-card{
      .mc-error{
        margin-bottom: 70px;
      }
    }
  }
}
