@import "variables";

.mat-mdc-paginator {
  color: $cc-subtitle-color;
  font-size: $small-font;
  border-top: 1px solid rgb(194, 193, 193);

  .mat-mdc-paginator-page-size-label, .mat-mdc-paginator-range-label {
    font-family: $font-secondary;
    color: $cc-subtitle-color;
    font-size: $small-font;
    font-weight: 500;
    line-height: $middle-font;
  }


  .mat-select-value {
    font-size: $middle-font;
  }

  .mat-form-field-appearance-outline .mat-mdc-form-field-flex {
    height: 36px;
  }

  .mat-mdc-paginator-page-size {
    display: flex;
    align-items: center;

    .mat-mdc-paginator-page-size-label {
      margin-right: 10px;
    }

    .mat-form-field-infix {
      border: none;
    }
  }

  .mat-paginator-page-size-select {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
    width: 100px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid $cc-border-color;


    .mat-form-field-wrapper {
      width: 60px;

      .mat-select-value {
        top: 0 !important;
      }

      .mat-select-trigger {
        top: 10px;
      }
    }

    .mat-select-arrow {
      margin: 0;
      top: 0 !important;
    }
  }

  .mat-form-field-underline {
    display: none;
  }
}

.mat-paginator-sticky {
  bottom: 0px;
  position: sticky;
  z-index: 10;
}

.mat-mdc-paginator-container {
  height: 50px;
  min-height: 50px !important;
}

.mat-option-text {
  font-size: $middle-font;
  font-family: $font-secondary;
}
