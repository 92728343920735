// Component wrapper
.mc-notify-message-wrapper {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  // Error message
  .error-message{
    @extend .reset;

    background: $error_background_color;
    color: $error_color;
  }

  .success-message {
    @extend .reset;

    background: $success_background_color;
    color: $success_color;
  }
}
