/*Filter wrapper*/
.mc-filter {
  background: $login-header-color;
  padding: 30px 20px 10px 30px;
  display: flex;
  display: -ms-flexbox;
  flex-wrap: wrap;
  position: relative;

  > * {
    margin-right: 20px;
    margin-bottom: 10px;
  }


  .fill-checkbox {
    margin-bottom: 0 !important;
  }

  .mc-login-input {
    padding: 14px 0;
    margin-bottom: 0 !important;
  }

  .mc-login-input .form-control {
    border-radius: 0;
  }

  .label-wrapper {
    display: flex;
    align-items: center;

    .custom-control {
      display: flex;

      &.fill-checkbox {
        --color: #35B1F1;

        .fill-control-input {
          display: none;

          &:checked ~ .fill-control-indicator {
            background-color: var(--color);
            border-color: var(--color);
            background-size: 80%;
          }
        }

        .fill-control-indicator {
          position: absolute;
          top: 4px;
          left: 0;
          width: 14px;
          height: 14px;
          background-color: $mc-white-background;
          border: 1px solid #aaa;
          background-size: 0;
          background-position: center;
          background-repeat: no-repeat;
          background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
        }

        .fill-control-description {
          font-size: 14px;
        }
      }
    }

    //.custom-control-input {
    //  // Box focus
    //  &:focus + label:before {
    //    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
    //  }
    //
    //  // Box checked
    //  &:checked + label:before {
    //    background: $login-blue-color;
    //  }
    //}
  }

  .select-wrapper .form-control {
    border-radius: 0;
  }


  .mc-filter-button {
    background: $login-blue-color;
    margin: 25px 0 20px auto;
    height: 41px;
  }

  .mc-filter-clear-button {
    font-size: 0.8rem;
  }

  .filter-clear-top-right{
    position: absolute;
    top:10px;
    right: 0;
  }

  .mc-search-top {

    .filter-types-wrapper {
      .filter-label {
        font-weight: 500;
        font-size: 15px;
      }

      .filter-background {
        background: #526a7d;
        color: $mc-white-background;
        padding: 2px 15px;
        font-size: 15px;

        .filter-name {
          text-transform: capitalize !important;
        }

        .remove-filter {
          background: $error_color;
          color: $mc-white-background;
          font-size: 13px;
          font-weight: 500;
          padding: 0 3px;

          &:hover {
            background: $mc-white-background;
            color: $error_color;
          }
        }
      }
    }
  }
}

.filter-container {
  display: flex;
  position: relative;
  padding: 30px 20px;
  background-color: $filter-bg;
  border-radius: $bottom-border-radius;

  .filter-inputs {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr) );
    grid-gap: 20px;
    width: 100%;

    .mcc-fi-textbox input, .mcc-fi-select select {
      width: 100%;
      border: 1px solid lighten($light-gray, 15%);
    }

    .close-ms {
      margin-top: $margin-small;
    }

    .mcc-multiselect {
      margin-top: 0px;
    }

    .mcc-multiselect-item-name {
      border: none;
      background-color: transparent;
      color: $mc-header-color;
    }

    label {
      position: absolute;
      color: $light-gray;
      font-size: $smallest-font;
      font-weight: 500;
      background-color: white;
      left: 13px;
      top: -7px;
      padding: 0px 3px;
      text-transform: capitalize;
    }

    input {
      border: 1px solid lighten($light-gray, 15%);

      &::placeholder {
        color: $mc-header-color;
      }
    }

    input::placeholder {
      font-weight: 300;
      opacity: .5;
    }
  }

  .filter-actions {
    position: relative;
    margin-left: $margin-extra-large;

    .clear-all {
      position: absolute;
      padding: 0px;
      right: 0;
      top: -30px;
      font-size: $small-font;
      font-weight: 500;
      color: #35b1ec !important;
      border: none;
      outline: none;
      background-color: $white-color !important;
      transition: $hover-transition !important;

      .mat-mdc-unelevated-button .mat-mdc-button-persistent-ripple::before {
        opacity: 0 !important;
      }
    }

    .filter-button {
      margin-top: 3px;
      background: $login-blue-color !important;
      border-radius: $border-radius-small;
      color: $white-color !important;
      min-width: 100px;
      height: 36px;
      transition: $hover-transition !important;
      &:hover {
        background-color: darken($mc-blue-text-color, 10%) !important;
      }
    }
  }

  .date-field {
    margin-top: 0 !important;

    .mcc-fi-date-picker {
      width: 100%;

      .date-range-input {
        padding-left: 15px !important;
      }
    }

    .form-group, .input-group, .input-group input {
      width: 100%;
    }

    .form-group {
      position: relative;

      i {
        position: absolute;
        display: flex;
        flex-basis: inherit;
        justify-content: flex-end;
        margin-right: $margin-large;
        font-size: $medium-font;
        color: $mc-header-color;
        right: 0;
        top: 12px;
        z-index: 10;
        pointer-events: none;
      }
    }
  }

  .date-range-input {
    height: 42px;
  }

  .mc-range-date-label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: -7px !important;
    left: 29px !important;
  }
}
