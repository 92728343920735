.mc-schedule-job-wrapper{
  .mc-tittle-wrapper {
    height: 60px;
  }

  .mc-error {
    position: relative;
    height: fit-content;
    margin-bottom: 30px;
    top: -4px;
  }
}
