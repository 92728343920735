// Placeholder
%mandatoryStar {
  content: '*';
  color: $error_color;
  padding-left: 3px;
}

%border-bottom-gray {
  border-bottom: 1px solid $mc-gray-line;
}


.mc-management-area {
  a.mc-create-new-user-btn {
    color: $mc-blue-text-color !important;
    cursor: pointer;
    font-weight: 500;

    &:hover {
      color: darken($mc-blue-text-color, 10%) !important;
    }
  }
  a.create-template-role-disabled-button {
    color: #cccccc8f !important;
    &:hover {
      color: #cccccc8f !important;
    }
  }
}


// Modal
.mc-create-new-user-modal {
  .modal-dialog {
    max-width: 1200px;
  }

  .mc-active {
    color: $login-blue-color;
    border-bottom: 3px solid $login-blue-color;
  }

  .mc-active-background {
    background: $login-blue-color;
  }

  .mc-semi-active {
    color: lighten($login-blue-color, 15%);
  }

  .mc-semi-active-background {
    background-color: lighten($login-blue-color, 15%);
  }

  .mc-error-field {
    border-color: $error_color;

    &:focus {
      border-color: $error_color;
      box-shadow: 0 0 0 0.2rem rgba(236, 35, 13, .25)
    }
  }

  .modal-content {
    border-radius: 0;
    padding: 10px 50px 50px 50px;
    position: relative;
  }

  .mc-create-user-modal-header {
    display: block;
    padding-left: 0;
    padding-right: 0;
    border-bottom: 0;

    .mc-create-user-entity-path {
      color: $mc-blue-text-color;
      font-size: 20px;
      font-weight: 500;

      span {
        &::after {
          content: '\f105';
          color: #8d969d;
          margin-left: 5px;
          margin-right: 5px;
          font-weight: 600;
          font-family: 'Font Awesome\ 5 Free';
          font-size: 14px;
        }

        &:last-child::after {
          content: '';
        }
      }
    }

    button.close.mc-new-user-close-btn {
      @extend %modalCloseBtn;
    }

  }

  .modal-body {
    background: $mc-gray-background;
    border-top: 3px solid $mc-gray-line;
  }

  ul.nav.nav-tabs {
    justify-content: center !important;
    border-bottom: none;
  }

  li.nav-item {
    padding: 0 10px;

    & > a.nav-link {
      background: transparent;
      border: none;
      color: $login-gray-color;
      display: flex;
      font-size: 20px;
      justify-content: space-between;
      align-items: center;
      padding: 5px;

      &.active {
        border-bottom: 3px solid $login-blue-color;
        color: $login-blue-color;

        .mc-notification-badge-gray {
          background: $login-blue-color;
        }
      }
    }
  }

  .mc-add-user-information {
    display: inline-block;
  }

  .mc-create-user-genders {
    &::before {
      border-color: $error_color;
    }
  }

  .mc-user-information {
    display: none;
  }

  .mc-show-user-info {
    display: flex;
  }

  .mc-user-email > label {
    &::after {
      @extend %mandatoryStar;
    }
  }

  .custom-control {
    &.fill-checkbox {
      --color: #35B1F1;

      .fill-control-input {
        display: none;

        &:checked ~ .fill-control-indicator {
          background-color: var(--color);
          border-color: var(--color);
          background-size: 80%;
        }
      }

      .fill-control-indicator {
        position: absolute;
        top: 4px;
        left: 0;
        width: 14px;
        height: 14px;
        border: 1px solid #aaa;
        background-size: 0;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
      }

      .fill-control-description {
        font-size: 11px;
      }
    }
  }

  .mc-create-new-user-entity-name {
    color: $mc-blue-text-color;
    font-size: 14px;
    font-weight: 500;
    padding-right: 5px;
    text-transform: uppercase;
  }

  .mc-create-user-parent-form {
    padding-top: 30px;
  }

  .mc-user-general-data-wrapper {
    height: 360px;
  }

  .mc-create-user-notify-wrapper {
    position: relative;
  }

  .mc-user-first-name,
  .mc-user-last-name,
  .mc-user-gender {
    .mc-new-user-field-names {
      &::after {
        @extend %mandatoryStar;
      }
      &.mc-no-mandatory::after {
        content: "";
      }
    }
  }

  .mc-new-user-field-names {
    font-size: 14px;
    font-weight: 500;
  }

  .mc-user-gender {
    padding: 0;

    label {
      margin-right: 10px;
      font-size: 14px;
    }

    & > div {
      margin-bottom: 10px;
    }
  }

  .mc-new-user-image-area {
    input[type="file"] {
      display: none;
    }
  }

  .mc-new-user-image {
    margin-top: 10px;
    min-width: 100%;
    height: 210px;
    cursor: pointer;
    background-color: $white;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-origin: content-box;
  }

  .mc-upload-image-btn:not([href]) {
    display: inline-block;
    font-size: 12px;
    color: $mc-blue-text-color;
    cursor: pointer;
    padding: 5px 0 5px 5px;
    float: right;

    &:hover {
      color: darken($mc-blue-text-color, 10%);
    }
  }

  .mc-new-user-search {
    position: relative;
    display: flex;
    align-items: center;
  }

  .mc-new-user-search-icon {
    color: $mc-gray-text-2;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }

  .mc-user-roles-area {
    .mc-roles-entity {
      font-size: 20px;
      font-weight: 500;
      padding-right: 10px;
    }

    .mc-role-is-selected {
      background-color: #d2e4f0;
    }

    .mc-roles-entity-selected {
      font-size: 20px;
      padding-right: 5px;
    }

    .mc-notification-badge-gray {
      background-color: $mc-blue-text-color;
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%);
    }

    .mc-user-roles-list {
      height: 330px;
      overflow-y: auto;

      .fill-control-indicator {
        background-color: $mc-white-background;
      }

      i {
        color: $mc-blue-text-color;
        float: right;
        line-height: inherit;
      }
    }
  }

  .mc-user-roles-item {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    column-gap: 7%;

    .mc-role-entity {
      display: block;
      padding: 10px 5px;
      @extend %border-bottom-gray;
      break-inside: avoid-column;
    }

    label {
      display: inline-block;
    }

    i {
      color: $mc-blue-text-color;
    }
  }

  .mc-show-selected-only {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .fill-control-indicator {

      background-color: $mc-white-background;
    }
  }

  .mc-user-roles-content {
    background-color: $mc-gray-background-2;
  }

  .mc-new-user-entity-name {
    font-size: 20px;
    font-weight: 500;
    padding-left: 5px;
    @extend %border-bottom-gray;

    span::before {
      font-family: Font Awesome\ 5 Free;
      display: inline-block;
      padding-right: 15px;
      content: "\f078";
      font-size: 14px;
      font-weight: 700;
      color: $mc-blue-text-color;
      cursor: pointer;
    }
  }

  .mc-new-user-entities-list {
    @extend %border-bottom-gray;

    .mc-notification-badge-gray {
      background: $login-blue-color;
      padding: 2px 5px;
    }
  }

  .mc-user-summary-section {
    border-right: 1px solid $mc-gray-line;

    .mc-new-user-image {
      cursor: default !important;
    }
  }

  .mc-user-summary-title {
    color: $login-blue-color;

    .fa-pen {
      cursor: pointer;
    }

    h6 {
      display: inline-block;
    }
  }

  .mc-user-summary-basic-info {
    div:first-child {
      font-size: 12px;
      font-weight: 600;
      text-transform: uppercase;
    }

    div:last-child {
      font-size: 16px;
    }
  }

  .mc-user-summary-value {
    min-height: 20px;
    height: auto;
  }

  .mc-user-summary-role-info {
    font-size: 14px;

    .mc-user-summary-role-name span:first-child {
      color: $login-blue-color;
      padding-right: 5px;
      font-weight: 500;
      text-transform: uppercase;
    }
  }

  .mc-user-summary-action-btn {
    display: flex;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 9;
  }

  .step-navigation-bar {
    div {
      padding: 0;
    }
  }

  .mc-user-summary-send-invite {
    text-align: left;
    display: flex;
    align-items: center;

    label {
      display: inline-block;
      padding-right: 10px;
    }

    i {
      font-size: 18px;
      color: $login-blue-color;
    }
  }

  .mc-user-summary-create-btn {
    text-align: center;
  }

  .mc-user-summary-createAdd-btn {
    text-align: right;
  }

  .mc-user-summary-create-btns {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .mc-next-save-btn:first-child {
      background-color: darken($login-blue-color, 10%);
    }
  }

}

.mc-create-user-parent-form {
  section.container {
    position: relative;
  }
}

